import React, { useEffect } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import ShopUpdateForm from "../../components/ShopUpdateForm";
import ShopCreationForm from "../../components/ShopCreationForm";

export default function ShopEdit({ location }) {
  let [searchParams] = useSearchParams();
  let { companyId } = useParams();

  useEffect(() => {
    console.debug("searchParams", searchParams);
  }, [searchParams]);

  useEffect(() => {
    console.debug("companyId", companyId);
  }, [companyId]);

  return searchParams && searchParams.get("id") ? (
    <ShopUpdateForm id={searchParams.get("id")} companyId={companyId} />
  ) : (
    <ShopCreationForm companyId={companyId} />
  );
}
