import React from "react";
import { ArrowUpCircleFill } from "react-bootstrap-icons";
import style from "./ScrollToTopButton.module.scss";

export default function ScrollToTopButton() {
  function handleClick(event) {
    event.preventDefault();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <ArrowUpCircleFill
      className={style["scroll-top-button"]}
      onClick={handleClick}
    />
  );
}
