import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export default function ShopNav() {
    const { shopId } = useParams();
    const { fetchData } = useAuth();
    const [shop, setShop] = useState(null);
    const { logout, user } = useAuth();

    const loadShop = async () => {
        let s = await fetchData({ endpoint: `shops/${shopId ? shopId : 0}` });
        setShop(s);
    }

    useEffect(() => {
        loadShop();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shopId]);

    return (
        <nav className="row justify-end bg-primary row padding-1">
            <Link className="color-white pl-1" to={"/shop"}>Accueil</Link>
            {
                shop && user.gpDirect &&
                <Link className="color-white pl-1" to={`/shop/${shop._id}/direct`}>Direct</Link>
            }
            <Link className="color-white pl-1" to={"/"} onClick={logout}>Déconnexion</Link>
        </nav>
    )

}