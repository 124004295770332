import React from "react";

export default function BrandLogo({ className }) {
    return (
        <svg className={`${className}`} style={{ maxWidth: 300}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 404.52 79.05">
            {/* <defs>
            <style>.cls-1{{fill:"#0d0e0e;"}}.cls-2{{fill:"#c5a37b;"}}</style>
        </defs> */}
            <g id="Calque_2" data-name="Calque 2">
                <g id="Calque_1-2" data-name="Calque 1">
                    <path fill="#0d0e0e" className="cls-1" d="M0,22.6C0,11.36,5.71,3.46,20.52,3.46c3.52,0,7.73.63,10.27.75v10l-1.33.29-.29-.23C28.65,7.67,24.62,5,20.52,5c-8.47,0-12,5.82-12,17.12,0,12.4,3.18,17.18,10.79,17.18,3.63,0,5.13-1.27,5.53-4.44a30.83,30.83,0,0,0,.17-3.4c0-.87-.05-2.25-.17-3.58-.12-1.61-.35-2.3-2.36-2.42-.64-.05-2.77-.17-3.35-.17l-.17-.17.17-1.44H25a60,60,0,0,0,7-.46l.17.17c-.06,1.32-.17,3-.17,5.19V39.61h-.35c-2.71,0-7.84,1.15-12.45,1.15C5.19,40.76,0,33.78,0,22.6Z" />
                    <path fill="#0d0e0e" className="cls-1" d="M36.84,34.88c0-4.33,3.29-7.38,12.46-7.38h1.9V21.62c0-3.4-.75-5-3.11-5-1.1,0-2.71.29-2.71,3.86,0,.87,0,2,0,3.06l-.92.58c-3-.35-6.11-2-6.11-4.56,0-2,2.36-4.38,9.4-4.38,9.28,0,11.07,3.86,11.07,9.74,0,2.6-.12,5.08-.12,6.92,0,2,0,3.52.06,5s.63,1.73,1.15,1.73c.81,0,1.33-.7,1.33-3.12v-1h1.32V36c0,3.92-1.9,4.73-4.72,4.73-3.41,0-5.54-1.56-6.29-5.13-1.5,4.44-4.9,5.13-7.67,5.13C40,40.76,36.84,39.2,36.84,34.88ZM51.2,31v-1.9h-.92c-3.69,0-5.19,1.84-5.19,5.53,0,2.13.57,3.4,1.84,3.4C49.3,38,51.2,34.48,51.2,31Z" />
                    <path fill="#0d0e0e" className="cls-1" d="M65.67,39.14l.17-.17c.81-.06,2-.17,2.31-.23,1-.23,1.15-1.09,1.21-2.88.06-2.31.23-11.7.23-15.8,0-5.42-.17-11.07-.29-14.7-.06-1.79-.11-3-1.61-3.17L65.84,2l-.17-.18.12-1h3.92A31.32,31.32,0,0,0,77.32,0l.17.17C77,6.74,77,15.39,77,17.53v3.92c0,4.15.12,13.54.17,14.64.06,1.33.18,2.48,1.27,2.65.58.12,1.56.17,2.19.23l.18.17-.12,1H65.79Z" />
                    <path fill="#0d0e0e" className="cls-1" d="M84.53,28c0-7.9,5-12.74,13-12.74s13,4.84,13,12.74-5,12.8-13,12.8S84.53,35.86,84.53,28Zm17.87,0c0-8.24-1-11.3-4.85-11.3S92.71,19.72,92.71,28s1,11.36,4.84,11.36S102.4,36.2,102.4,28Z" />
                    <path fill="#0d0e0e" className="cls-1" d="M115,28c0-7.32,4.73-12.74,13.49-12.74,7.15,0,9.51,2.65,9.51,4.5,0,2.88-4.15,4.38-6.17,4.38l-.86-.81V21.68c0-4-1.1-5-2.77-5-4.44,0-5.13,4.73-5.13,11,0,5.25,1.33,11.36,6.51,11.36,1.62,0,5.19-.06,7.56-4.73l1.09.52c-2.36,5.59-7.15,6-10,6C119.46,40.76,115,35.74,115,28Z" />
                    <path fill="#0d0e0e" className="cls-1" d="M140.45,39.14l.18-.17c.8-.06,2-.17,2.3-.23,1-.23,1.15-1.09,1.21-2.88.06-2.31.23-11.7.23-15.8,0-5.42-.17-11.07-.29-14.7-.05-1.79-.11-3-1.61-3.17L140.63,2l-.18-.18.12-1h3.63a35.18,35.18,0,0,0,8-.81l.17.17c-.35,5-.52,7.73-.52,13.55,0,2.19-.23,5.25-.4,6.63a9.25,9.25,0,0,1,8.7-5.13c6.92,0,9.63,4.09,9.63,9.57,0,2.07-.06,5.48-.06,6.46,0,1.32,0,3.69.06,4.78s.11,2.54,1.33,2.71a19.16,19.16,0,0,0,2,.23l.17.17-.12,1H158.61l-.11-1,.17-.17c.81-.06,1.79-.17,2.08-.23,1-.17,1.21-1.15,1.26-2.65.06-2,.12-4.84.12-6.69s-.06-3.63-.06-5.94c0-4-1.61-5.82-4.26-5.82-3.12,0-6,3.11-6,8v4.21c0,1.39.06,4.9.12,6.23s.17,2.48,1.32,2.65c.75.12,1.5.17,2.13.23l.18.17-.12,1H140.57Z" />
                    <path fill="#0d0e0e" className="cls-1" d="M176.89,27.84c0-7,4.84-12.62,13.72-12.62,6.63,0,9.52,3.17,9.52,8.47,0,.47-.06,1.27-.12,2l-.75.69H185v.75c0,7.38,2.14,11.82,6.4,11.82,2.65,0,5.48-.69,7.44-4.73l1.15.58c-2.19,5.07-6.28,5.94-9.74,5.94C181,40.76,176.89,35.22,176.89,27.84Zm15.74-3.4c.06-.92.06-1.44.06-1.84,0-3.75-.64-5.94-3-5.94-2.94,0-4.44,2.94-4.67,8.53Z" />
                    <path fill="#c5a37b" className="cls-2" d="M213.39,26.23c0-6.92,4.32-10.61,10.32-12.11A11.37,11.37,0,0,1,222.9,10c0-4.49,3.92-6.51,9.34-6.51,4.85,0,8.25,2.07,8.25,5.19,0,2.36-2.6,3.92-4.56,4.09l-.75-.58c.06-.74.12-2,.12-2.65,0-2.07-.23-4.61-2.94-4.61-3,0-6,3.23-6,8.59v.35c4.27,0,6.29,2.19,6.29,3.8,0,.92-.7,2-2.54,2a6,6,0,0,1-5.54-3.81c-3.22,2-3.28,7.15-3.28,10,0,5.14,2.07,13.38,11.76,13.38s11.76-5.88,11.76-11.65a16.91,16.91,0,0,0-.52-4.15h-3.8c-4.73,0-7,.35-7,3.58,0,2.19,1.39,3.86,4.44,3.86h1.21v1.33h-1.33c-3.91,0-6.22-2.31-6.22-5.88,0-5.13,3.34-9.34,13.2-9.34h4.38c4.61,0,9.11-.52,9.11-5.48A6.71,6.71,0,0,0,251.44,5c-2.82,0-3.11,1.78-3.11,4.73,0,.51,0,1.38,0,1.84l-.63.69c-4.09,0-4.84-2.65-4.84-3.63,0-2.71,2.59-5.13,7.49-5.13,7.32,0,10.21,5,10.21,9.28,0,5.59-2.66,10-10.44,10.72a13.27,13.27,0,0,1,.46,3.64c0,6.16-5,13.66-18.27,13.66C218,40.76,213.39,32.63,213.39,26.23Z" />
                    <path fill="#0d0e0e" className="cls-1" d="M273.87,39l.18-.17c.69,0,2-.06,3-.17s1.21-1.91,1.26-2.71c.18-4.5.18-9.11.18-13.72,0-3.58-.06-9.81-.18-13.67,0-1-.28-2.82-1.26-2.94s-2.37-.17-3.06-.17l-.17-.17L274,4h17c9.62,0,13.43,3.74,13.43,9.68,0,7.09-5.54,11-13.89,11a33.83,33.83,0,0,1-3.87-.23V35.8c.06.81.29,2.48,1.5,2.6.75.11,4.38.4,5.36.46l.18.17-.12,1.15H274.05Zm22.49-25.13c0-4.27-1.1-8.36-6-8.36h-3.69V22.89c1,.11,2.6.17,3.12.17C294.86,23.06,296.36,19.77,296.36,13.84Z" />
                    <path fill="#0d0e0e" className="cls-1" d="M305.53,34.88c0-4.33,3.28-7.38,12.45-7.38h1.9V21.62c0-3.4-.75-5-3.11-5-1.1,0-2.71.29-2.71,3.86,0,.87,0,2,.06,3.06l-.92.58c-3-.35-6.12-2-6.12-4.56,0-2,2.37-4.38,9.4-4.38,9.28,0,11.07,3.86,11.07,9.74,0,2.6-.11,5.08-.11,6.92,0,2,0,3.52.05,5s.64,1.73,1.16,1.73c.8,0,1.32-.7,1.32-3.12v-1h1.33V36c0,3.92-1.9,4.73-4.73,4.73-3.4,0-5.53-1.56-6.28-5.13-1.5,4.44-4.9,5.13-7.67,5.13C308.7,40.76,305.53,39.2,305.53,34.88ZM319.88,31v-1.9H319c-3.69,0-5.19,1.84-5.19,5.53,0,2.13.58,3.4,1.85,3.4C318,38,319.88,34.48,319.88,31Z" />
                    <path fill="#0d0e0e" className="cls-1" d="M337.18,33.09c0-.92.06-4.78.06-6.57,0-3.06,0-6.92.06-9.17-.35,0-2.6-.11-3.11-.11l-.18-.18.12-1.09c5.42-.75,8.41-3.34,9.45-9h1.21V15.8h5.48l.11,1.09-.17.17-4.5.35a1,1,0,0,0-1,1.15c0,3.69-.11,7.32-.11,9.57,0,2,.06,6.23.17,8.07.12,2.14.81,2.54,1.79,2.54,1.73,0,2.94-1.27,2.94-4v-.29h1.33v.34c0,4.9-2.71,6-6.58,6C340.81,40.76,337.18,38.63,337.18,33.09Z" />
                    <path fill="#0d0e0e" className="cls-1" d="M353.73,39.14l.17-.17c.75-.06,1.73-.17,2.08-.23,1.15-.23,1.21-1.44,1.27-2.71.05-1.84.11-4.61.11-6.51,0-.81-.06-6.86-.17-9.46-.06-1.9-.12-2.65-1.21-2.82a19.82,19.82,0,0,0-2-.23l-.17-.18.11-1h3.63a37.07,37.07,0,0,0,7.73-.81l.17.17c-.17,1.62-.34,3.29-.4,4.9-.12,2.19-.17,5.59-.17,8,0,2.53.05,6.86.11,8.07.06,1.44.23,2.48,1.27,2.65.29.06,1.38.17,2.13.23l.17.17-.11,1H353.84Zm2.54-32.45a4.38,4.38,0,1,1,8.76,0,4.38,4.38,0,0,1-8.76,0Z" />
                    <path fill="#0d0e0e" className="cls-1" d="M372.06,39.14l.18-.17c.75-.06,1.73-.17,2.07-.23,1-.23,1.16-1.32,1.21-2.71.06-1.84.12-4.61.12-6.51,0-.81-.06-6.81-.17-9.23-.06-2.13-.12-2.88-1.21-3.05a20.15,20.15,0,0,0-2-.23l-.18-.18.12-1h3.11A38.32,38.32,0,0,0,383,15l.17.17a42.13,42.13,0,0,0-.28,5,9.18,9.18,0,0,1,8.64-4.9c6.92,0,9.57,4.09,9.57,9.57,0,2.07-.05,5.48-.05,6.46,0,1.32,0,3.69.05,4.78s.12,2.54,1.33,2.71a19.18,19.18,0,0,0,2,.23l.17.17-.11,1H389.94l-.12-1L390,39c.81-.06,1.79-.17,2.08-.23,1-.17,1.21-1.15,1.27-2.65.06-2,.11-4.84.11-6.69s-.05-3.63-.05-5.94c0-4-1.62-5.82-4.33-5.82s-5.94,2.71-5.94,8V30c0,1,.06,4.84.12,6.05s.17,2.42,1.21,2.65a20.43,20.43,0,0,0,2.25.23l.17.17-.12,1H372.18Z" />
                    <path fill="#0d0e0e" className="cls-1" d="M105.51,78.9a7.82,7.82,0,1,1,6.79-11.64c-1.32.69-1.43.75-2.9,1.46a4.49,4.49,0,0,0-3.89-2.22,4.58,4.58,0,0,0,0,9.16,4.5,4.5,0,0,0,4-2.4c1.47.76,1.51.84,2.9,1.47A7.78,7.78,0,0,1,105.51,78.9Z" />
                    <path fill="#0d0e0e" className="cls-1" d="M125.71,79.05a8,8,0,1,1,8-8A8,8,0,0,1,125.71,79.05Zm-4.71-8a4.69,4.69,0,1,0,4.71-4.73A4.75,4.75,0,0,0,121,71.08Z" />
                    <path fill="#0d0e0e" className="cls-1" d="M148.76,73.15l4.32,5.73h-4.15L145,73.33h-1.66v5.55H140V63.43h6.95c3.89,0,5.38,2.44,5.38,4.95a4.62,4.62,0,0,1-3.67,4.77Zm-5.38-7.08v4.64H146c1.62,0,3-.37,3-2.33s-1.34-2.31-3-2.31Z" />
                    <path fill="#0d0e0e" className="cls-1" d="M159.88,63.43h6.66c4,0,7,2.53,7,7.63,0,4.47-2.28,7.8-7,7.8h-6.66ZM163.27,76h3c2,0,3.84-1.21,3.84-4.62,0-3.11-1.1-5.1-4.45-5.1h-2.42Z" />
                    <path fill="#0d0e0e" className="cls-1" d="M187.08,79.05a8,8,0,1,1,8-8A8,8,0,0,1,187.08,79.05Zm-4.71-8a4.69,4.69,0,1,0,4.71-4.73A4.75,4.75,0,0,0,182.37,71.08Z" />
                    <path fill="#0d0e0e" className="cls-1" d="M204,68.21V78.88h-3V63.39h3.89L211.81,73V63.39h2.87V78.88h-2.87Z" />
                    <path fill="#0d0e0e" className="cls-1" d="M224.32,68.21V78.88h-3V63.39h3.89L232.14,73V63.39H235V78.88h-2.87Z" />
                    <path fill="#0d0e0e" className="cls-1" d="M244.88,72.7V76h8.71v2.85h-12V63.37h11.25v2.85h-8V69.7h7v3Z" />
                    <path fill="#0d0e0e" className="cls-1" d="M269.1,73.15l4.32,5.73h-4.15l-3.89-5.55h-1.66v5.55H260.3V63.43h7c3.89,0,5.38,2.44,5.38,4.95A4.62,4.62,0,0,1,269,73.15Zm-5.38-7.08v4.64h2.63c1.62,0,3-.37,3-2.33s-1.34-2.31-3-2.31Z" />
                    <path fill="#0d0e0e" className="cls-1" d="M280.22,63.43h3.39V78.86h-3.39Z" />
                    <path fill="#0d0e0e" className="cls-1" d="M293.81,72.7V76h8.7v2.85h-12V63.37h11.26v2.85h-8V69.7h7v3Z" />
                </g>
            </g>
        </svg>
    )
}