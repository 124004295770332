import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export default function WorkshopNav() {
    const { logout } = useAuth();

    return (
        <nav className="row justify-end bg-primary row padding-1">
            <Link className="color-white" to={"/"} onClick={logout}>Déconnexion</Link>
        </nav>
    )
}