import React, { useEffect, useState } from "react";
import { PencilFill } from "react-bootstrap-icons";
import Modal from "react-modal";
import { useAuth } from "../hooks/useAuth";
import ProductTypesCheckboxes from "./ProducTypesCheckboxes ";
import ScheduleDeliveryCheckboxes from "./ScheduleDeliveriesCheckboxes";
import style from "./ShopUpdateForm.module.scss";

export default function ShopUpdateForm({ id, companyId, onSubmit }) {
  const { fetchData } = useAuth();

  const [isOpen, setIsOpen] = useState(false);
  // const [address, setAddress] = useState(null);
  const [values, setValues] = useState({
    name: "",
    reference: 0,
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    address: "",
    address2: "",
    zipCode: "",
    city: "",
    schedule: "",
    products: [],
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    await fetchData({
      endpoint: `shops/${id}`,
      method: "PUT",
      data: {
        ...values,
      },
    });
    setIsOpen(false);
    onSubmit && onSubmit();
  };

  const loadData = async () => {
    const shop = await fetchData({
      endpoint: `shops/${id}`,
      method: "GET",
    });

    setValues({
      ...shop,
    });
  };

  const handleScheduleChange = (schedule) => {
    setValues({ ...values, schedule });
  };

  const handleProductsChange = (products) => {
    setValues({
      ...values,
      products: Object.entries(products)
        .map((p) => (p[1] ? p[0] : null))
        .filter((p) => p),
    });
  };

  const isSumitDisbabled = () => {
    return (
      !values.name ||
      !values.contactName ||
      !values.contactEmail ||
      !values.contactPhone ||
      !values.contactEmail ||
      !values.address ||
      !values.city ||
      !values.zipCode ||
      !values.schedule ||
      !values.products
    );
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <button
        className={`button-s ${style["modal-button"]}`}
        onClick={() => setIsOpen(true)}
      >
        <PencilFill />
      </button>
      <Modal
        className={style["modal"]}
        buttonTitle={"Modifier"}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        overlayClassName={style["modal-overlay"]}
      >
        <div className="row padding-1 bg-white">
          <form onSubmit={handleSubmit}>
            <div className="row w-100">
              <div className="col-6 pl-1 pr-1">
                <div className="col-12">
                  <label
                    id={`${companyId}-shop-ref-label`}
                    htmlFor={`${companyId}-shop-ref-input`}
                  >
                    Référence
                  </label>
                  <input
                    type="number"
                    pattern="[0-9]+"
                    value={values.reference}
                    id={`${companyId}-shop-ref-input`}
                    name="reference"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <label
                    id={`${companyId}-shop-name-label`}
                    htmlFor={`${companyId}-shop-name-input`}
                  >
                    Nom
                  </label>
                  <input
                    id={`${companyId}-shop-name-input`}
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <label
                    id={`${companyId}-shop-contact-name-label`}
                    htmlFor={`${companyId}-shop-contact-name-input`}
                  >
                    Contact
                  </label>
                  <input
                    id={`${companyId}-shop-contact-name-input`}
                    name="contactName"
                    value={values.contactName}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <label
                    id={`${companyId}-shop-contact-email-label`}
                    htmlFor={`${companyId}-shop-contact-email-input`}
                  >
                    Email
                  </label>
                  <input
                    id={`${companyId}-shop-contact-email-input`}
                    name="contactEmail"
                    value={values.contactEmail}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <label
                    id={`${companyId}-shop-contact-phone-label`}
                    htmlFor={`${companyId}-shop-contact-phone-input`}
                  >
                    Téléphone
                  </label>
                  <input
                    id={`${companyId}-shop-contact-phone-input`}
                    name="contactPhone"
                    value={values.contactPhone}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-6 pl-1 pr-1">
                <div className="col-12">
                  <label
                    id={`${companyId}-shop-address-label`}
                    htmlFor={`${companyId}-shop-address-input`}
                  >
                    Adresse
                  </label>
                  <input
                    id={`${companyId}-shop-address-input`}
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <label
                    id={`${companyId}-shop-address2-label`}
                    htmlFor={`${companyId}-shop-address2-input`}
                  >
                    Adresse 2
                  </label>
                  <input
                    id={`${companyId}-shop-address2-input`}
                    name="address2"
                    value={values.address2}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <label
                    id={`${companyId}-shop-zipCode-label`}
                    htmlFor={`${companyId}-shop-zipCode-input`}
                  >
                    Code postal
                  </label>
                  <input
                    id={`${companyId}-shop-zipCode-input`}
                    name="zipCode"
                    value={values.zipCode}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <label
                    id={`${companyId}-shop-city-label`}
                    htmlFor={`${companyId}-shop-city-input`}
                  >
                    Ville
                  </label>
                  <input
                    id={`${companyId}-shop-city-input`}
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-12 mt-1 padding-1">
                <ScheduleDeliveryCheckboxes
                  initial={values.schedule}
                  id={companyId}
                  onChange={handleScheduleChange}
                />
                <hr />
                <ProductTypesCheckboxes
                  initial={values.products}
                  id={companyId}
                  onChange={handleProductsChange}
                />
                <button className="button-s" disabled={isSumitDisbabled()}>
                  Valider
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
