import "./styles/styles.scss";
import { useRoutes } from "react-router-dom";
import { LoginPage } from "./pages";
import { CompanyPage } from "./pages/company";
import { ShopPage } from "./pages/shop";
import {
  AccountsPage,
  CompaniesPage,
  OrdersPage,
  ShopOrdersPage,
  AdminRootPage,
} from "./pages/admin";
import { ProtectedRoute } from "./components/ProtectedRoute";
import OrderCreationForm from "./components/OrderCreationForm";
import CompanyRootPage from "./pages/company/CompanyRootPage";
import CompanyCalendarPage from "./pages/company/CompanyCalendarPage";
import ShopRootPage from "./pages/shop/ShopRootPage";
import ShopCalendarPage from "./pages/shop/ShopCalendarPage";
import LogisticRootPage from "./pages/logistic/LogisticRootPage";
import WorkshopRootPage from "./pages/workshop/WorkshopRootPage";
import CollectionsPage from "./pages/logistic/CollectionsPage";
import DeliveryPage from "./pages/logistic/DeliveryPage";
import ChronopostPage from "./pages/admin/ChronopostPage";
import WorksPage from "./pages/workshop/WorksPage";
import ShopDirectPage from "./pages/shop/ShopDirectPage";
import DirectCreationForm from "./components/DirectCreationForm";
import { useEffect } from "react";
import ShopEdit from "./pages/admin/ShopEdit";
import AdminShopPage from "./pages/admin/AdminShopPage";
import AdminShopHistoryPage from "./pages/admin/AdminShopHistoryPage";
import ShopHistoryPage from "./pages/shop/ShopHistoryPage";

function App() {
  useEffect(() => {
    document.title = "Galoche & Patin";
  }, []);

  const routes = useRoutes([
    {
      path: "/",
      element: <LoginPage />,
    },
    {
      path: "/admin",
      element: (
        <ProtectedRoute>
          <AdminRootPage />
        </ProtectedRoute>
      ),
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute>
              <OrdersPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "accounts",
          element: (
            <ProtectedRoute>
              <AccountsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "companies",
          element: (
            <ProtectedRoute>
              <CompaniesPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "companies/:companyId/shop/form",
          element: (
            <ProtectedRoute>
              <ShopEdit />
            </ProtectedRoute>
          ),
        },
        {
          path: "shops/:shopId",
          element: (
            <ProtectedRoute>
              <AdminShopPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "shops/:shopId/history",
          element: (
            <ProtectedRoute>
              <AdminShopHistoryPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "shop/:shopId/orders",
          element: (
            <ProtectedRoute>
              <ShopOrdersPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "shop/:shopId/orders/new",
          element: (
            <ProtectedRoute>
              <OrderCreationForm />
            </ProtectedRoute>
          ),
        },
        {
          path: "shop/:shopId/orders/:orderId",
          element: (
            <ProtectedRoute>
              <OrderCreationForm />
            </ProtectedRoute>
          ),
        },
        {
          path: "chronopost",
          element: (
            <ProtectedRoute>
              <ChronopostPage />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/company",
      element: (
        <ProtectedRoute>
          <CompanyRootPage />
        </ProtectedRoute>
      ),
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute>
              <CompanyPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "calendar",
          element: (
            <ProtectedRoute>
              <CompanyCalendarPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "shop/:shopId",
          element: (
            <ProtectedRoute>
              <ShopPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "shop/:shopId/orders/new",
          element: (
            <ProtectedRoute>
              <OrderCreationForm />
            </ProtectedRoute>
          ),
        },
        {
          path: "shop/:shopId/orders/:orderId",
          element: (
            <ProtectedRoute>
              <OrderCreationForm />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/shop",
      element: (
        <ProtectedRoute>
          <ShopRootPage />
        </ProtectedRoute>
      ),
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute>
              <ShopPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "history/:shopId",
          element: (
            <ProtectedRoute>
              <ShopHistoryPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "calendar",
          element: (
            <ProtectedRoute>
              <ShopCalendarPage />
            </ProtectedRoute>
          ),
        },
        {
          path: ":shopId/orders/new",
          element: (
            <ProtectedRoute>
              <OrderCreationForm />
            </ProtectedRoute>
          ),
        },
        {
          path: ":shopId/orders/:orderId",
          element: (
            <ProtectedRoute>
              <OrderCreationForm />
            </ProtectedRoute>
          ),
        },
        {
          path: ":shopId/direct",
          element: (
            <ProtectedRoute>
              <ShopDirectPage />
            </ProtectedRoute>
          ),
        },
        {
          path: ":shopId/direct/new",
          element: (
            <ProtectedRoute>
              <DirectCreationForm />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/logistic",
      element: (
        <ProtectedRoute>
          <LogisticRootPage />
        </ProtectedRoute>
      ),
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute>
              <CollectionsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "deliveries",
          element: (
            <ProtectedRoute>
              <DeliveryPage />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/workshop",
      element: (
        <ProtectedRoute>
          <WorkshopRootPage />
        </ProtectedRoute>
      ),
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute>
              <WorksPage />
            </ProtectedRoute>
          ),
        },
      ],
    },
  ]);

  return routes;
}

export default App;

export const PICKUP_ZIPCODES = [
  "92130",
  "97132",
  "92190",
  "92360",
  "92310",
  "92312",
  "92210",
  "92100",
  "92150",
  "92800",
  "92500",
  "92014",
  "92200",
  "92400",
  "92300",
  "92110",
  "92600",
  "92270",
  "92700",
  "92036",
  "92390",
  "92035",
  "94300",
  "94410",
  "94160",
  "94130",
  "75048",
  "94340",
  "94130",
  "94033",
  "94220",
  "93100",
  "93170",
  "94800",
  "92170",
  "92240",
  "92120",
  "94270",
  "94250",
  "94205",
  "92320",
  "94230",
  "93460",
  "94110",
  "93400",
  "93200",
  "93206",
  "93210",
  "93284",
  "93300",
  "93500",
  "93022",
  "93310",
  "93260",
  "75000",
  "75001",
  "75002",
  "75003",
  "75004",
  "75005",
  "75006",
  "75007",
  "75008",
  "75009",
  "75010",
  "75011",
  "75012",
  "75013",
  "75014",
  "75015",
  "75016",
  "75017",
  "75018",
  "75019",
  "75020",
  "75116",
  "75270",
  "75680",
];
