import React from "react";
import { Outlet } from "react-router-dom";
import ShopNav from "../../components/ShopNav";

export default function ShopRootPage() {
    return (
        <>
            <ShopNav />
            <Outlet />
        </>
    )
}