import style from "./ProductTypeSelector.module.scss";
import { translate } from "../translation";
import { useState } from "react";

export default function ProductTypeSelector({ types, onSelected, value }) {
  const [selected, setSelected] = useState(value);

  function handleClick(type) {
    setSelected(type);
    onSelected && onSelected(type);
  }

  return (
    <div className={style["root"]}>
      <h3>Type d'article*</h3>
      <div className={style["container"]}>
        {types.map((t) => (
          <button
            key={t}
            className={`${style["selectable"]} ${
              selected === t ? style["selected"] : ""
            }`}
            onClick={() => handleClick(t)}
          >
            {translate(t)}
          </button>
        ))}
      </div>
    </div>
  );
}
