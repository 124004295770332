import React, { useEffect, useState } from "react";
import UserCreationForm from "../../components/UserCreationFoms";
import { useAuth } from "../../hooks/useAuth";

export default function AccountsPage() {
  const { fetchData } = useAuth();
  const [users, setUsers] = useState(null);

  const loadUsers = async () => {
    let u = await fetchData({ endpoint: "users" });
    setUsers(u);
  };

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    await fetchData({ endpoint: "users" });
    window.location.reload(false);
  };

  const printUserAccess = (user) => {
    if (user.role === "shop")
      return user.shops.map((s) => (
        <p className="bold" key={s._id}>
          {s.name}
        </p>
      ));
    else if (user.role === "company")
      return user.companies.map((c) => (
        <p className="bold" key={c._id}>
          {c.name}
        </p>
      ));
    return <p className="bold">N/A</p>;
  };

  return (
    <>
      <div className="col-12 padding-2 align-center h-100">
        <div className="col-8 mt-2">
          {users &&
            users.map((user) => (
              <details className="w-100 bg-white" key={user._id}>
                <summary className="color-white">{user.username}</summary>
                <div className="pl-2 mt-1">
                  <h5>Role</h5>
                  <p className="bold">{user.role}</p>
                  <h5>Accès</h5>
                  {printUserAccess(user)}
                </div>
              </details>
            ))}
        </div>
        <UserCreationForm onsubmit={handleSubmit} />
      </div>
    </>
  );
}
