import React, { useEffect, useState } from "react";
import CompaniesList from "../../components/CompaniesList";
import CompanySummary from "../../components/CompanySummary";
import PageLayout from "../../components/PageLayout";
import { useAuth } from "../../hooks/useAuth";
import CompanyCreationForm from "../../components/CompanyCreationForm";

export default function CompaniesPage() {
  const { fetchData } = useAuth();
  const [companies, setCompanies] = useState(null);

  const loadCompanies = async () => {
    let c = await fetchData({ endpoint: "companies" });
    setCompanies(c.sort((a, b) => a.name.localeCompare(b.name)));
  };

  useEffect(() => {
    loadCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout>
      <CompaniesList>
        {companies &&
          companies.map((company) => (
            <CompanySummary
              key={`company-${company._id}`}
              company={company}
              onUpdate={() => loadCompanies()}
            />
          ))}
      </CompaniesList>
      <CompanyCreationForm onSubmit={() => {}} />
      {/* <ShopCreationForm
        // onClick={handleToggleShopDetail(`${company._id}-shop-form`)}
        // open={shopOpenDetail === `${company._id}-shop-form`}
        // minRef={company.shops.length + 1}
        onSubmit={() => window.location.reload(false)}
        companyId={company._id}
      /> */}
      {/* <div className="col-12 padding-2 justify-center align-center">
        <div className="col-8">
          {companies &&
            companies.map((company) => (
              <details
                open={companyOpenDetail === company._id}
                className="w-100"
                key={company._id}
                id={company._id}
              >
                <summary
                  onClick={handleToggleCompanyDetail(company._id)}
                  className="color-white"
                >
                  {company.name}
                </summary>
                <>
                  <div className="reverse-row margin-1">
                    <CompanyUpdateForm
                      id={company._id}
                      onSubmit={() => loadCompanies()}
                    />
                  </div>
                  {company.shops.map((shop) => (
                    <details
                      onClick={handleToggleShopDetail(shop._id)}
                      open={shopOpenDetail === shop._id}
                      key={shop._id}
                      id={shop._id}
                      className="margin-1"
                    >
                      <summary className="color-white">
                        {company.reference +
                          "-" +
                          shop.reference +
                          " " +
                          shop.name}
                      </summary>
                      <div className="reverse-row margin-1">
                        <ShopUpdateForm
                          id={shop._id}
                          minRef={0}
                          companyId={company._id}
                          onSubmit={() => loadCompanies()}
                        />
                      </div>
                      <div
                        onClick={(event) => event.stopPropagation()}
                        className="row padding-1 bg-white"
                      >
                        <div className="col-8">
                          <h5>Contact</h5>
                          <p className="bold">
                            {shop.contactName} - {shop.contactEmail} -{" "}
                            {shop.contactPhone}
                          </p>
                          <h5>Adresse</h5>
                          <p className="bold">
                            {shop.address + " " + shop.city}
                          </p>
                          <h5>Planning livraison</h5>
                          <p className="bold">
                            {formatRawScheduleToStringArray(shop.schedule).join(
                              " - "
                            )}
                          </p>
                          <h5>Types d'articles</h5>
                          <p className="bold">
                            {shop.products.map((p) => translate(p)).join(", ")}
                          </p>
                        </div>
                        <div className="col-4">
                          <button
                            className="button-s"
                            onClick={() =>
                              navigate(`/admin/shop/${shop._id}/orders`)
                            }
                          >
                            Commandes
                          </button>
                        </div>
                        <div className="row justify-between w-100">
                          <ChronopostShipping
                            shop={shop}
                            type="collection"
                            text="Télécharger collecte Chronopost"
                          />
                          <ChronopostShipping
                            shop={shop}
                            type="delivery"
                            text="Télécharger livraison Chronopost"
                          />
                        </div>
                      </div>
                      <hr />
                    </details>
                  ))}
                </>

                <ShopCreationForm
                  onClick={handleToggleShopDetail(`${company._id}-shop-form`)}
                  open={shopOpenDetail === `${company._id}-shop-form`}
                  minRef={company.shops.length + 1}
                  onSubmit={() => window.location.reload(false)}
                  companyId={company._id}
                />
              </details>
            ))}
          <CompanyCreationForm onSubmit={handleSubmit} />
        </div>
      </div> */}
    </PageLayout>
  );
}
