import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { formatDate, printState } from "../../utils";

export default function CompanyPage() {
    const { fetchData } = useAuth();
    const navigate = useNavigate();
    const [shops, setShops] = useState(null);

    const loadShops = async () => {
        let s = await fetchData({ endpoint: "shops" });
        setShops(s);
    }

    useEffect(() => {
        loadShops();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="col-12 justify-center align-center padding-2">
            <div className="col-12">
                {
                    shops && shops.map(s => (
                        <div className="bg-primary br-4 padding-1 color-white">
                            <div className="row justify-between pb-1">
                                <h3>{s.name}</h3>
                                <button className="button-s outline color-white" onClick={() => navigate(`shop/${s._id}`)}>Voir boutique</button>
                            </div>
                            <hr />
                            <div >
                                {
                                    s.orders.map(o => (
                                        <div className="row">
                                            <div className="row w-100 justify-between">
                                                <p>{`Collecte du ${formatDate(o.collectionDate)}`}</p>
                                                <p>{`${o.products.length} articles`}</p>
                                                <p>{printState(o.state)}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}