import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { translate } from "../translation";
import { formatComments, formatDate, printState } from "../utils";
import AlertDialog from "./AlertDialog";

export default function ShopOrders({
  shopId,
  filter,
  appendEditPath = false,
  displayShopName = false,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { fetchData, user } = useAuth();

  const [orders, setOrders] = useState(null);

  const loadOrders = async () => {
    let o = await fetchData({ endpoint: `shops/${shopId}/orders` });
    setOrders(o);
  };

  useEffect(() => {
    loadOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopId]);

  const handleEditClick = (orderId) => {
    let origin = location.pathname.split("/")[1];
    let pathname = "";
    switch (origin) {
      case "admin":
        pathname = `${orderId}`;
        break;
      case "company":
        pathname = `orders/${orderId}`;
        break;
      case "shop":
        pathname = `${shopId}/orders/${orderId}`;
        break;
      default:
        break;
    }
    navigate(pathname);
  };

  const handleValidateCollection = async (orderId) => {
    await fetchData({
      endpoint: `order/${orderId}`,
      method: "POST",
      data: { state: "COLLECTION_IN_PROGRESS" },
    });
    await loadOrders();
  };

  const handleValidateWorkshopCollection = async (orderId) => {
    await fetchData({
      endpoint: `order/${orderId}`,
      method: "POST",
      data: { state: "COLLECTED" },
    });
    await loadOrders();
  };

  const handleValidateWorkshopDelivery = async (orderId) => {
    await fetchData({
      endpoint: `order/${orderId}`,
      method: "POST",
      data: { state: "DELIVERY_IN_PROGRESS" },
    });
    await loadOrders();
  };

  const handleValidateDelivery = async (orderId) => {
    await fetchData({
      endpoint: `order/${orderId}`,
      method: "POST",
      data: { state: "DELIVERED" },
    });
    await loadOrders();
  };

  const filteredOrders = () => {
    if (!filter) return orders.filter((o) => o.state !== "DELIVERED");
    return orders.filter(
      (o) =>
        o.state !== "DELIVERED" &&
        o.products.some((p) => p.reference.toString().includes(filter))
    );
  };

  return (
    <div className="col-12 justify-center align-center">
      <div className="col-12">
        {orders &&
          filteredOrders().map((o) => (
            <details className="w-100" key={o._id}>
              <summary className="color-white">
                <div className="row w-100 justify-between">
                  {o.state === "PENDING" ? (
                    <p className="mb-0 bold">
                      {displayShopName ? `${o.shop.name} - ` : ""}{" "}
                      {`Nouvelle commande`}
                    </p>
                  ) : (
                    <p className="mb-0 bold">
                      {displayShopName ? `${o.shop.name} - ` : ""}{" "}
                      {`Enlèvement ${formatDate(new Date(o.collectionDate))}`}
                    </p>
                  )}

                  <p className="bold mb-0">{printState(o.state)}</p>
                </div>
              </summary>
              <div className="row padding-1 bg-light">
                <div className="col-10 padding-1">
                  {o.products.map((p) => (
                    <div key={p._id} className="row">
                      <div className="row justify-between pr-1 w-100">
                        <h4 className="bold">
                          {p.reference}
                          {" - "}
                          {translate(p.type)}
                        </h4>
                        {o.state === "COLLECTED" && (
                          <p>{p.state === "done" ? "Terminé" : "A faire"}</p>
                        )}
                      </div>
                      <div className="col-6">
                        <p className="bold">Prestation</p>
                        {p.type === "climbing_shoes" && (
                          <ul>
                            {p.work && <li>{translate(p.work)}</li>}
                            {p.rubber && <li>{p.rubber}</li>}
                            {p.thickness && <li>{p.thickness}</li>}
                          </ul>
                        )}
                        {p.type !== "climbing_shoes" && (
                          <ul>{p.works && <li>{p.works.join(", ")}</li>}</ul>
                        )}
                      </div>
                      <div className="col-6">
                        <p className="bold">Commentaires</p>
                        <ul>{formatComments(p.comments)}</ul>
                      </div>
                      <div className="col-12">
                        <hr className="mt-1 mb-1" />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-2">
                  <button
                    className="button-s"
                    disabled={o.state !== "PENDING" && user.role !== "admin"}
                    onClick={() => handleEditClick(o._id)}
                  >
                    Modifier
                  </button>
                  {
                    o.state === "COLLECTION_PENDING" && (
                      <AlertDialog
                        buttonText={"Mon colis à été récupéré"}
                        title="Confirmation de prise en charge Chronopost"
                        message="Votre colis a-t-il bien été pris en charge par Chronopost ?"
                        onAccept={() => handleValidateCollection(o._id)}
                        onCancel={() => {}}
                        question={true}
                      />
                    )
                    // <button className="button-s" disabled={o.state !== "COLLECTION_PENDING"} onClick={() => handleValidateCollection(o._id)}>Mon colis à été récupéré</button>
                    // <button className="button-s" onClick={() => handleValidateDelivery(o._id)}>J'ai reçu mon colis</button>
                  }
                  {(user.role === "admin" || user.role === "logistic") && (
                    <button
                      className="button-s"
                      disabled={o.state !== "COLLECTION_IN_PROGRESS"}
                      onClick={() => handleValidateWorkshopCollection(o._id)}
                    >
                      Valider Reception Atelier
                    </button>
                  )}
                  {(user.role === "admin" || user.role === "logistic") &&
                    o.state === "COLLECTED" && (
                      <button
                        className="button-s"
                        onClick={() => handleValidateWorkshopDelivery(o._id)}
                      >
                        Valider Envoi Atelier
                      </button>
                    )}
                  {
                    o.state === "DELIVERY_IN_PROGRESS" && (
                      <AlertDialog
                        buttonText={"J'ai reçu mon colis"}
                        title="Confirmation réception Chronopost"
                        message="Avez-vous bien récupéré votre colis ?"
                        onAccept={() => handleValidateDelivery(o._id)}
                        onCancel={() => {}}
                        question={true}
                      />
                    )
                    // <button className="button-s" onClick={() => handleValidateDelivery(o._id)}>J'ai reçu mon colis</button>
                  }
                </div>
              </div>
            </details>
          ))}
      </div>
    </div>
  );
}
