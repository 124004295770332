import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export default function CompanyNav() {
    const { logout } = useAuth();

    return (
        <nav className="row justify-end bg-primary row padding-1">
            <Link className="color-white pl-1" to={"/company"}>Boutiques</Link>
            {/* <Link className="color-white" to={"/company/calendar"}>Calendrier</Link> */}
            <Link className="color-white pl-1" to={"/"} onClick={logout}>Déconnexion</Link>
        </nav>
    )

}