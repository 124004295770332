import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import DirectDateSelection from "./DirectDateSelection";
import ProductForm from "./ProductForm";
import SavedProduct from "./SavedProduct";

export default function DirectCreationForm() {
    const { shopId } = useParams();
    const navigate = useNavigate();

    const { fetchData } = useAuth();

    const [collect, setCollect] = useState(null);
    const [delivery, setDelivery] = useState(null);
    const [autocompleteData, setAutocompleteData] = useState([]);

    const familyNameInput = useRef();
    const givenNameInput = useRef();
    const emailInput = useRef();
    const phoneInput = useRef();
    const addressInput = useRef();
    const cityInput = useRef();
    const zipCodeInput = useRef();
    const commentInput = useRef();

    const [products, setProducts] = useState([]);

    const loadAutocomplete = async (text) => {
        const response = await fetchData({ endpoint: `autocomplete?input=${text}` });
        return response;
    }

    const loadDetails = async (placeId) => {
        const response = await fetchData({ endpoint: `autocomplete/details?place_id=${placeId}` })
        return response;
    }

    const handleAutocompleteInputChange = async ({ target }) => {
        const text = target.value;
        if (text.includes("France")) {
            const place = autocompleteData.find(d => d.message === text);
            if (place) {
                const details = await loadDetails(place.id);
                const comp = details?.result?.address_components;
                if (!comp)
                    return;
                let city = comp.find(c => c.types.some(t => t === "locality")).long_name;
                let zipcode = comp.find(c => c.types.some(t => t === "postal_code")).long_name;
                cityInput.current.value = city;
                zipCodeInput.current.value = zipcode;
                return;
            }
        }
        if (text.length < 6) return;
        const results = await loadAutocomplete(text);
        if (results.predictions) {
            setAutocompleteData(results.predictions.map(p => ({ message: p.description, id: p.place_id })))
        } else {
            setAutocompleteData([]);
        }
    }

    const handleProductSubmit = async (productData) => {
        setProducts([...products, { ...productData, productData, work: productData.works.toString() }]);
    }

    const validateForm = () => {
        return (
            familyNameInput.current &&
            givenNameInput.current &&
            emailInput.current &&
            phoneInput.current &&
            addressInput.current &&
            cityInput.current &&
            zipCodeInput.current &&
            products.length > 0 &&
            collect &&
            delivery &&
            shopId
        )
    }

    const handleFormSubmit = async () => {
        const data = {
            customer: {
                familyName: familyNameInput.current?.value,
                givenName: givenNameInput.current?.value,
                email: emailInput.current?.value,
                phone: phoneInput.current?.value,
                address: addressInput.current?.value,
                city: cityInput.current?.value,
                zipCode: zipCodeInput.current?.value,
                comment: commentInput.current?.value,
            },
            products: products,
            collect: collect,
            delivery: delivery,
            shopId: shopId
        }

        try {
            await fetchData({ endpoint: "direct", method: "POST", data })
            navigate(-1);
        } catch (error) {

        }
    }

    const handleProductDelete = (reference) => {
        setProducts(products.filter(p => p.reference !== reference));
    }

    return (
        <div className="col-12 padding-2">
            <div className="col-12 padding-1 br-4 bg-white">
                <div className="col-12">
                    <h3 className="bold mb-1">Nouvelle commande directe</h3>
                    <form>
                        <h5 className="bold mb-1">Adresse</h5>
                        <div className="row">
                            <div className="col-6">
                                <div className="col-12 mb-1">
                                    <label id="customer-familyname-label" htmlFor="customer-familyname-input">Nom de famille</label>
                                    <input type="text" id="customer-familyname-input" name="familyName" ref={familyNameInput} />
                                </div>
                                <div className="col-12 mb-1">
                                    <label id="customer-givenname-label" htmlFor="customer-givenname-input">Prénom</label>
                                    <input type="text" id="customer-givenname-input" name="givenName" ref={givenNameInput} />
                                </div>

                                <div className="col-12 mb-1">
                                    <label id="customer-email-label" htmlFor="customer-email-input">Email</label>
                                    <input type="text" id="customer-email-input" name="email" ref={emailInput} />
                                </div>

                                <div className="col-12 mb-1">
                                    <label id="customer-phone-label" htmlFor="customer-phone-input">Téléphone</label>
                                    <input type="text" id="customer-phone-input" name="phone" ref={phoneInput} />
                                </div>
                            </div>
                            <div className="col-6">

                                <datalist id="addresses-list">
                                    {
                                        autocompleteData.map(d => (
                                            <option value={d.message} />
                                        ))
                                    }
                                </datalist>

                                <div className="col-12 mb-1">
                                    <label id="customer-address-label" htmlFor="customer-address-input">Adresse</label>
                                    <input type="text" id="customer-address-input" name="address" ref={addressInput} list={"addresses-list"} size={10} autoComplete={"off"} onChange={handleAutocompleteInputChange} />
                                </div>

                                <div className="col-12 mb-1">
                                    <label id="customer-city-label" htmlFor="customer-city-input">Ville</label>
                                    <input type="text" id="customer-city-input" name="city" ref={cityInput} />
                                </div>

                                <div className="col-12 mb-1">
                                    <label id="customer-zipcode-label" htmlFor="customer-zipcode-input">Code postal</label>
                                    <input type="text" id="customer-zipcode-input" name="zipCode" ref={zipCodeInput} />
                                </div>

                                <div className="col-12 mb-1">
                                    <label id="customer-comment-label" htmlFor="customer-comment-input">Commentaires</label>
                                    <input type="text" id="customer-comment-input" name="comment" ref={commentInput} />
                                </div>
                            </div>
                        </div>
                        {/* <button className="button-s mt-1 mb-1" type="submit">Valider</button> */}
                    </form>

                    <DirectDateSelection address={null} minDate={new Date()} onCollectChange={collect => setCollect(collect)} onDeliveryChange={delivery => setDelivery(delivery)} />

                    {
                        products.map((p, i) => <SavedProduct shop={shopId} reference={p.reference} productType={p.type} comment={p.comments} works={p.works} onDelete={handleProductDelete} />)
                    }
                    <ProductForm onSubmit={productData => handleProductSubmit(productData)} />

                    <button className="button-s" disabled={!validateForm()} onClick={handleFormSubmit}>Envoyer</button>

                </div>
            </div>

        </div >
    )
}