import React, { useEffect, useState } from "react";

export default function ScheduleDeliveryCheckboxes({ id, onChange, initial }) {
  const [schedule, setSchedule] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thirsday: false,
    friday: false,
  });

  const handleChange = ({ target }) => {
    const { name, checked } = target;
    let s = { ...schedule, [name]: checked };
    setSchedule(s);
    onChange(s);
  };

  useEffect(() => {
    if (!initial) return;
    if (typeof initial === "string" || initial instanceof String) {
      let splitted = (initial || "00000").split("");
      let s = {
        monday: splitted.at(0) === "1",
        tuesday: splitted.at(1) === "1",
        wednesday: splitted.at(2) === "1",
        thirsday: splitted.at(3) === "1",
        friday: splitted.at(4) === "1",
      };
      setSchedule(s);
      onChange(s);
    } else {
      setSchedule(initial);
      onChange(initial);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initial]);

  return (
    <div className="col-12 justify-center align-center mb-1">
      <h4 className="bold">Passages</h4>
      <div className="row w-100 justify-center align-center">
        <div className="col-2">
          <label id={`${id}-label-monday`} htmlFor={`${id}-checkbox-monday`}>
            Lundi
          </label>
          <input
            // onClick={() => console.debug("TOTOTTOTTO")}
            onChange={handleChange}
            id={`${id}-checkbox-monday`}
            checked={schedule.monday}
            type="checkbox"
            name="monday"
          />
        </div>
        <div className="col-2">
          <label id={`${id}-label-tuesday`} htmlFor={`${id}-checkbox-tuesday`}>
            Mardi
          </label>
          <input
            onChange={handleChange}
            id={`${id}-checkbox-tuesday`}
            checked={schedule.tuesday}
            type="checkbox"
            name="tuesday"
          />
        </div>
        <div className="col-2">
          <label
            id={`${id}-label-wednesday`}
            htmlFor={`${id}-checkbox-wednesday`}
          >
            Mercredi
          </label>
          <input
            onChange={handleChange}
            id={`${id}-checkbox-wednesday`}
            checked={schedule.wednesday}
            type="checkbox"
            name="wednesday"
          />
        </div>
        <div className="col-2">
          <label
            id={`${id}-label-thirsday`}
            htmlFor={`${id}-checkbox-thirsday`}
          >
            Jeudi
          </label>
          <input
            onChange={handleChange}
            id={`${id}-checkbox-thirsday`}
            checked={schedule.thirsday}
            type="checkbox"
            name="thirsday"
          />
        </div>
        <div className="col-2">
          <label id={`${id}-label-friday`} htmlFor={`${id}-checkbox-friday`}>
            Vendredi
          </label>
          <input
            onChange={handleChange}
            id={`${id}-checkbox-friday`}
            checked={schedule.friday}
            type="checkbox"
            name="friday"
          />
        </div>
      </div>
    </div>
  );
}
