import React, { useState } from "react";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useAuth } from "../hooks/useAuth";

export default function CompanyCreationForm({ onSubmit }) {
    const { fetchData } = useAuth();

    const [address, setAddress] = useState(null);
    const [values, setValues] = useState({
        name: null,
        reference: 0,
    });

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setValues({ ...values, [name]: value });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        await fetchData({ endpoint: "company", method: "POST", data: { ...values, address: address.label, gplace: address.value.place_id } })
        onSubmit();
    }

    return (
        <details className="w-100">
            <summary className="color-white">Nouvelle entreprise</summary>
            <div className="row padding-1 bg-white">
                <form className="w-100" onSubmit={handleSubmit}>
                    <div className="row justify-between">
                        <div className="col-6">
                            <label id={`company-ref-label`} htmlFor={`company-ref-input`}>Référence</label>
                            <input type="number" pattern="[0-9]+" defaultValue={0} id={`company-ref-input`} name="reference" onChange={handleChange} />
                        </div>
                        <div className="col-6">
                            <label id="company-name-label" htmlFor="company-name-input">Nom</label>
                            <input className="w-100" id="company-name-input" name="name" onChange={handleChange} />
                        </div>
                    </div>
                    <label id={`company-address-label`}>Adresses</label>
                    <GooglePlacesAutocomplete
                        apiKey="AIzaSyA7QSOvarr7JG0r3lR71pYXkQowp09YrVA"
                        selectProps={{
                            address,
                            onChange: setAddress,
                        }}
                    />
                    <button className="button-s mt-1 mb-1">Ajouter</button>
                </form>
            </div>
        </details>
    )
}