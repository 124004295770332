import { translate } from "../translation";
import Checkbox from "./Checkbox";

export default function SavedProduct({ shop, reference, productType, comment, works, onDelete }) {
    return (
        <div>
            <button className="button-s" style={{ margin: "auto", padding: 4, backgroundColor: "red", color: "white", fontWeight: "bold" }} onClick={() => onDelete && onDelete(reference)}>X</button>
            <h5 className="bold mb-1">
                Prestations
            </h5>
            <div className="row mb-1">
                <div className="col-3">
                    <label id="product-new-reference-label" htmlFor="product-new-reference-input">Numéro ticket</label>
                    <input disabled={true} type="text" id="product-new-reference-input" value={reference} />
                </div>
                <div className="col-3">
                    <label id={`product-new-type-select-label`} htmlFor={`product-new-type-select`}>Type d'article</label>
                    <input id={`product-new-type-select`} name="type" value={translate(productType)} />
                    {/* <option value="">Sélectionnez un type</option>
                        {
                            shop && shop.products && shop.products.map(p => (
                                <option key={p} value={p}>{translate(p)}</option>
                            ))
                        } */}
                    {/* </select> */}
                </div>

                <div className="col-3">
                    <label id="product-comment-reference-label" htmlFor="product-comment-reference-input">Commentaires</label>
                    <input type="text" id="product-comment-reference-input" value={comment} />
                </div>

                <div className="col-3">
                    <label>Prestations</label>
                    {
                        shop && works &&
                        works.map((work, workIndex) => (
                            <Checkbox
                                disabled={true}
                                index={workIndex}
                                label={work}
                                isChecked={true}
                                onCheck={() => { }}
                            />

                        ))
                    }
                    {/* <button className="button-s" type="button" onClick={handleFormSubmit}>Ajouter</button> */}
                </div>

            </div>
        </div>
    )
}