import { useState } from "react";
import Modal from "react-modal";
import style from "./RequestValidate.module.scss";
import etiquette1 from "../images/etiquette-gp1.webp";
import etiquette2 from "../images/etiquette-gp2.webp";

export default function RequestValidate({
  refPrefix,
  isEnabled,
  onValidate,
  value,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [requestReference, setRequestReference] = useState(value);

  function openModal() {
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }

  function handleReferenceChange({ target }) {
    const { value } = target;
    setRequestReference(value);
  }

  function isValid() {
    return requestReference && requestReference.length > 0;
  }

  function handleValidate(event) {
    event.preventDefault();
    onValidate && onValidate(requestReference);
  }

  return (
    <>
      <div className={style["modal-button-container"]}>
        <button
          disabled={!isEnabled}
          className={`button-s ${style["modal-button"]}`}
          onClick={openModal}
        >
          Valider
        </button>
      </div>
      <Modal
        className={style["modal"]}
        buttonTitle={"Modifier"}
        isOpen={modalOpen}
        onRequestClose={closeModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        overlayClassName={style["modal-overlay"]}
      >
        <form onSubmit={handleValidate} className={style["content"]}>
          <h3>Numéro ticket</h3>
          <div className={style["input-line"]}>
            {refPrefix}
            <input
              autoFocus
              name="request-reference"
              onChange={handleReferenceChange}
              value={requestReference}
            />
          </div>
          <hr />
          <div className={style["explanations"]}>
            <div className={style["explanation"]}>
              <img
                className={style["image"]}
                src={etiquette2}
                alt="etiquette 1"
              />
              <div>Placez la partie supérieure du ticket avec l'article</div>
            </div>

            <div className={style["explanation"]}>
              <img
                className={style["image"]}
                src={etiquette1}
                alt="etiquette 2"
              />
              <div>Donnez la partie inférieure du ticket au client</div>
            </div>
          </div>

          <div className={style["explanation"]}>
            <div>
              Merci de nous fournir les chaussures par <strong>paire</strong>
            </div>
          </div>

          <div className={style["explanation"]}>
            <div>
              Merci de retirer <strong>embauchoirs</strong> et{" "}
              <strong>pochons</strong>
            </div>
          </div>

          <div className={style["button-container"]}>
            <button
              type="submit"
              disabled={!isValid()}
              className={`button-s ${style["validate-button"]}`}
              // onClick={handleValidate}
            >
              Valider
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}
