import React, { useState } from "react";
import { useAuth } from "../hooks/useAuth";
import AdminCompanySelect from "./AdminCompanySelect";
import AdminShopSelect from "./AdminShopSelect";

export default function UserCreationForm({ onsubmit }) {
    const { fetchData } = useAuth();

    const [values, setValues] = useState({
        username: null,
        password: null,
        confirmPassword: null,
        role: "",
        companies: null,
        shops: null
    });

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setValues({ ...values, [name]: value });
    }

    const handleCheckboxChange = ({ target }) => {
        const { name, checked } = target;
        setValues({ ...values, [name]: checked });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        await fetchData({ endpoint: `user/`, method: 'POST', data: values });
        onsubmit();
    }

    return (
        <div className="col-8 pl-2 pr-2 br-4 align-center bg-white br-4">
            <h4 className=" bold">Nouveau compte</h4>
            <form className="w-100" onSubmit={handleSubmit}>
                <div className="row justify-center">
                    <div className="col-6">
                        <div className="col-12 mb-1">
                            <label id="username-label" htmlFor="username-input">Nom d'utilisateur</label>
                            <input type="text" id="username-input" name="username" onChange={handleChange} />
                        </div>

                        <div className="col-12 mb-1">
                            <label id="password-label" htmlFor="password-input">Mot de passe</label>
                            <input type="password" id="password-input" name="password" onChange={handleChange} />
                        </div>

                        <div className="col-12 mb-1">
                            <label id="confirm-password-label" htmlFor="confirm-password-input">Confirmation</label>
                            <input type="password" id="confirm-password-input" name="confirmPassword" onChange={handleChange} />
                        </div>

                        <div className="row">
                            <input style={{ marginLeft: "10px" }} type="checkbox" id={`user-direct-order-input`} name="gpDirect" onChange={handleCheckboxChange} />
                            <label id={`user-direct-order-label`} htmlFor={`user-direct-order-input`}>Direct</label>
                        </div>

                        <div className="col-12 mb-1">
                            <label id="role-label" htmlFor="role-select">Role</label>
                            <select id="role-select" name="role" value={values.role} onChange={handleChange}>
                                <option value="">Sélectionnez un role</option>
                                <option value="shop">Boutique</option>
                                <option value="company">Entreprise</option>
                                <option value="logistic">Logistique</option>
                                <option value="workshop">Atelier</option>
                                <option value="admin">Admin</option>
                            </select>
                        </div>

                        {
                            values.role === "shop" &&
                            <AdminShopSelect onChange={handleChange} />
                        }

                        {
                            values.role === "company" &&
                            <AdminCompanySelect onChange={handleChange} />
                        }
                        <div className="row justify-end mt-1 mb-1">
                            <button className="button-s" type="submit">Valider</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}