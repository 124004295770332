import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import RequestList from "../../components/RequestList";
import ShopPageHeader from "../../components/ShopPageHeader";
import { useAuth } from "../../hooks/useAuth";
import SearchBar from "../../components/SearchBar";

export default function AdminShopHistoryPage() {
  const { shopId } = useParams();
  const { fetchData } = useAuth();

  const [history, setHistory] = useState(null);
  const [shop, setShop] = useState(null);
  const [filter, setFilter] = useState("");

  const loadShop = async (shopId) => {
    let response = await fetchData({ endpoint: `shops/${shopId}` });
    setShop(response);
  };

  const loadHistory = async (shopId) => {
    let response = await fetchData({
      endpoint: `shop/${shopId}/history-requests/`,
    });
    setHistory(response);
  };

  useEffect(() => {
    loadShop(shopId);
    loadHistory(shopId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopId]);

  function handleChange({ target }) {
    setFilter(target.value);
  }
  return (
    <PageLayout>
      <ShopPageHeader shop={shop} />
      <SearchBar onChange={handleChange} />
      {history &&
        history
          .sort((a, b) => new Date(b._id) - new Date(a._id))
          .map((h) => (
            <RequestList
              key={h._id}
              filter={filter}
              shop={shop}
              title={new Date(h._id).toLocaleDateString("fr")}
              requests={h.products}
            />
          ))}
    </PageLayout>
  );
}
