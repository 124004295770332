import style from "./ShopPageHeader.module.scss";

export default function ShopPageHeader({ shop, displayContact = true }) {
  return (
    shop && (
      <div className={style["header"]}>
        <div className={style["title"]}>
          <h1>
            {" "}
            {shop.parentReference}-{shop.reference} {shop.name}
          </h1>
        </div>
        {displayContact && (
          <div className={style["summary"]}>
            <div className={style["contact"]}>
              <div>{shop.contactName}</div>
              <div>{shop.contactEmail}</div>
              <div>{shop.contactPhone}</div>
            </div>
            {/* <div className={style["address"]}>
            <div>
              {shop.address} {shop.zipCode} {shop.city}
            </div>
          </div> */}
          </div>
        )}
      </div>
    )
  );
}
