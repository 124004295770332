const termTable = {
    "climbing_shoes": "Chaussons d'escalade",
    "man_shoes": "Chaussures homme",
    "woman_shoes": "Chaussures femme",
    "kid_shoes": "Chaussures enfant",
    "sneakers": "Sneakers",
    "leather_goods": "Maroquinerie",
    "leather_good": "Maroquinerie",
    "enrobage": "Ressemelage et enrobage",
    "ressemelage": "Ressemelage"
}

export const translate = (term) => termTable[term] || term;