import React, { useEffect, useState } from "react";

export default function ProductTypesCheckboxes({ id, onChange, initial }) {
  const [products, setProducts] = useState({
    man_shoes: false,
    woman_shoes: false,
    kid_shoes: false,
    climbing_shoes: false,
    sneakers: false,
    leather_good: false,
  });

  const handleChange = ({ target }) => {
    const { name, checked } = target;
    let s = { ...products, [name]: checked };
    setProducts(s);
    onChange(s);
  };

  useEffect(() => {
    if (!initial) return;
    if (Array.isArray(initial)) {
      let p = { ...products };
      initial.forEach((i) => (p[i] = true));
      setProducts(p);
    } else {
      setProducts(initial);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initial]);

  return (
    <div className="col-12 justify-center align-center mb-1">
      <h4 className="bold">Articles</h4>
      <div className="row w-100 justify-center align-center">
        <div className="col-2">
          <label
            id={`${id}-label-man_shoes`}
            htmlFor={`${id}-checkbox-man_shoes`}
          >
            Homme
          </label>
          <input
            onChange={handleChange}
            id={`${id}-checkbox-man_shoes`}
            checked={products.man_shoes}
            type="checkbox"
            name="man_shoes"
          />
        </div>
        <div className="col-2">
          <label
            id={`${id}-label-woman_shoes`}
            htmlFor={`${id}-checkbox-woman_shoes`}
          >
            Femme
          </label>
          <input
            onChange={handleChange}
            id={`${id}-checkbox-woman_shoes`}
            checked={products.woman_shoes}
            type="checkbox"
            name="woman_shoes"
          />
        </div>
        <div className="col-2">
          <label
            id={`${id}-label-kid_shoes`}
            htmlFor={`${id}-checkbox-kid_shoes`}
          >
            Enfant
          </label>
          <input
            onChange={handleChange}
            id={`${id}-checkbox-kid_shoes`}
            checked={products.kid_shoes}
            type="checkbox"
            name="kid_shoes"
          />
        </div>
        <div className="col-2">
          <label
            id={`${id}-label-sneakers`}
            htmlFor={`${id}-checkbox-sneakers`}
          >
            Sneakers
          </label>
          <input
            onChange={handleChange}
            id={`${id}-checkbox-sneakers`}
            checked={products.sneakers}
            type="checkbox"
            name="sneakers"
          />
        </div>
        <div className="col-2">
          <label
            id={`${id}-label-climbing_shoes`}
            htmlFor={`${id}-checkbox-climbing_shoes`}
          >
            Escalade
          </label>
          <input
            onChange={handleChange}
            id={`${id}-checkbox-climbing_shoes`}
            checked={products.climbing_shoes}
            type="checkbox"
            name="climbing_shoes"
          />
        </div>
        <div className="col-2">
          <label
            id={`${id}-label-leather_good`}
            htmlFor={`${id}-checkbox-leather_good`}
          >
            Maroquinerie
          </label>
          <input
            onChange={handleChange}
            id={`${id}-checkbox-leather_good`}
            checked={products.leather_good}
            type="checkbox"
            name="leather_good"
          />
        </div>
      </div>
    </div>
  );
}
