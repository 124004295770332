import React, { useRef, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import Modal from "react-modal";
import style from "./ChronopostShipping.module.scss";
import { BoxFill } from "react-bootstrap-icons";

Modal.setAppElement("#root");

export default function ChronopostShipping({ shop, order, type, text }) {
  const { fetchData } = useAuth();

  const [modalOpen, setModalOpen] = useState(false);

  const collectRef = useRef(null);
  const deliveryRef = useRef(null);
  const formatRef = useRef(null);

  const handleDownloadChronopostShipping = async (shop, order, type, pdf) => {
    let endpoint = `chronopost/shop/${shop._id}/${type}?pdf=${pdf}`;
    if (order) endpoint = `chronopost/order/${order._id}/${type}`;
    let chrono = await fetchData({ endpoint: endpoint, isBlob: true });

    // chronos.forEach((chrono) => {
    // const bytes = new TextEncoder().encode(JSON.stringify(chrono.buffer));
    let blobUrl = window.URL.createObjectURL(chrono);
    let a = document.createElement("a");

    a.href = blobUrl;
    a.target = "_blank";
    a.download = `${shop.name}-${type}-chronopost.txt`;
    document.body.appendChild(a);
    a.click();
    a.remove();

    let iframe = document.createElement("iframe"); //load content in an iframe to print later
    document.body.appendChild(iframe);

    iframe.style.display = "none";
    iframe.src = blobUrl;
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
    // });
  };

  function closeModal() {
    setModalOpen(false);
  }

  function openModal() {
    setModalOpen(true);
  }

  function handleClick(event) {
    event.preventDefault();
    openModal();
  }

  function handleSubmit(event) {
    event.preventDefault();
    let collect = collectRef.current?.checked;
    let delivery = deliveryRef.current?.checked;

    let type = "none";
    if (collect) {
      type = "collection";
    } else if (delivery) {
      type = "delivery";
    }
    if (collect && delivery) {
      type = "both";
    }
    handleDownloadChronopostShipping(
      shop,
      order,
      type,
      formatRef.current?.value === "PDF"
    );
  }

  return (
    <div>
      <button
        className={style["modal-button"]}
        // onClick={() => handleDownloadChronopostShipping(shop, order, type)}
        onClick={handleClick}
      >
        <BoxFill className={style["modal-icon"]} />
      </button>
      <Modal
        className={style["modal"]}
        isOpen={modalOpen}
        onRequestClose={closeModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
      >
        <h3>Téléchargement étiquette</h3>
        <form onSubmit={handleSubmit}>
          <div className={style["line"]}>
            <input
              id="collect-input"
              type="checkbox"
              ref={collectRef}
              defaultChecked={true}
            />
            <label htmlFor="collect-input">{"Collecte (client -> gp)"}</label>
          </div>
          <div className={style["line"]}>
            <input
              id="delivery-input"
              type="checkbox"
              ref={deliveryRef}
              defaultChecked={true}
            />
            <label htmlFor="delivery-input">{"Livraison (gp -> client)"}</label>
          </div>
          <div className={style["line"]}>
            <select id="format-select" ref={formatRef}>
              <option value={"ZPL"}>ZPL</option>
              <option value={"PDF"}>PDF</option>
            </select>
          </div>
          <button className={`button-s ${style["button"]}`} type="submit">
            Télécharger
          </button>
        </form>
      </Modal>
    </div>
  );
}
