import React, { useState } from "react";
import BrandLogo from "../components/BrandLogo";
import { useAuth } from "../hooks/useAuth";

export default function LoginPage() {
    const { login } = useAuth();

    const [values, setValues] = useState({
        username: null,
        password: null
    });

    const handleChange = ({ target }) => {
        const { value, name } = target;
        setValues({ ...values, [name]: value })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        login(values);
    }

    return (
        <div className="col-12 padding-2 justify-center align-center">
            <BrandLogo className="mb-2" />
            <div className="bg-white br-4 padding-1">
                <form onSubmit={handleSubmit}>
                    <div className="col-12 mb-1">
                        <label htmlFor="username-input">Utilisateur</label>
                        <input id="username-input" name="username" onChange={handleChange} />
                    </div>
                    <div className="col-12 mb-2">
                        <label htmlFor="password-input">Mot de passe</label>
                        <input type="password" id="password-input" name="password" onChange={handleChange} />
                    </div>
                    <div className="col-12">
                        <button type="submit" className="button-s" id="form-button" name="validate">Se connecter</button>
                    </div>
                </form>
            </div>
        </div>
    )
}