import React, { useState } from "react";
import { useAuth } from "../hooks/useAuth";
import ProductTypesCheckboxes from "./ProducTypesCheckboxes ";
import ScheduleDeliveryCheckboxes from "./ScheduleDeliveriesCheckboxes";
import Modal from "react-modal";
import style from "./ShopCreationForm.module.scss";
import { PlusCircleFill } from "react-bootstrap-icons";

export default function ShopCreationForm({ minRef, company, onSubmit }) {
  const { fetchData } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState({
    reference: minRef,
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setValues({ ...values, [name]: value });
  };

  const handleScheduleChange = (schedule) => {
    setValues({ ...values, schedule });
  };

  const handleProductsChange = (products) => {
    setValues({
      ...values,
      products: Object.entries(products)
        .map((p) => (p[1] ? p[0] : null))
        .filter((p) => p),
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await fetchData({
      endpoint: `company/${company._id}/shop`,
      method: "POST",
      data: { ...values, parentReference: company.reference },
    });
    onSubmit && onSubmit();
  };

  const isSumitDisbabled = () => {
    return (
      !values.name ||
      !values.contactName ||
      !values.contactEmail ||
      !values.contactPhone ||
      !values.contactEmail ||
      !values.address ||
      !values.city ||
      !values.zipCode ||
      !values.schedule ||
      !values.products
    );
  };
  return (
    <>
      <button
        className={`button-s ${style["modal-button"]}`}
        onClick={() => setIsOpen(true)}
      >
        <span className={style["modal-button-text"]}>Ajouter</span>
        <PlusCircleFill />
      </button>
      <Modal
        className={style["modal"]}
        buttonTitle={"Modifier"}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        overlayClassName={style["modal-overlay"]}
      >
        {/* <details className="margin-1" open={open}>
          <summary onClick={onClick} className="bg-secondary">
            Nouvelle boutique
          </summary> */}
        <div className="padding-1 bg-white">
          <form onSubmit={handleSubmit}>
            <div className="row w-100">
              <div className="col-6 pl-1 pr-1">
                <div className="col-12">
                  <label
                    id={`${company._id}-shop-ref-label`}
                    htmlFor={`${company._id}-shop-ref-input`}
                  >
                    Référence
                  </label>
                  <input
                    type="number"
                    pattern="[0-9]+"
                    defaultValue={minRef}
                    id={`${company._id}-shop-ref-input`}
                    name="reference"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <label
                    id={`${company._id}-shop-name-label`}
                    htmlFor={`${company._id}-shop-name-input`}
                  >
                    Nom
                  </label>
                  <input
                    id={`${company._id}-shop-name-input`}
                    name="name"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <label
                    id={`${company._id}-shop-contact-name-label`}
                    htmlFor={`${company._id}-shop-contact-name-input`}
                  >
                    Contact
                  </label>
                  <input
                    id={`${company._id}-shop-contact-name-input`}
                    name="contactName"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <label
                    id={`${company._id}-shop-contact-email-label`}
                    htmlFor={`${company._id}-shop-contact-email-input`}
                  >
                    Email
                  </label>
                  <input
                    id={`${company._id}-shop-contact-email-input`}
                    name="contactEmail"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <label
                    id={`${company._id}-shop-contact-phone-label`}
                    htmlFor={`${company._id}-shop-contact-phone-input`}
                  >
                    Téléphone
                  </label>
                  <input
                    id={`${company._id}-shop-contact-phone-input`}
                    name="contactPhone"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-6 pl-1 pr-1">
                <div className="col-12">
                  <label
                    id={`${company._id}-shop-address-label`}
                    htmlFor={`${company._id}-shop-address-input`}
                  >
                    Adresse
                  </label>
                  <input
                    id={`${company._id}-shop-address-input`}
                    name="address"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <label
                    id={`${company._id}-shop-address2-label`}
                    htmlFor={`${company._id}-shop-address2-input`}
                  >
                    Adresse 2
                  </label>
                  <input
                    id={`${company._id}-shop-address2-input`}
                    name="address2"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <label
                    id={`${company._id}-shop-zipCode-label`}
                    htmlFor={`${company._id}-shop-zipCode-input`}
                  >
                    Code postal
                  </label>
                  <input
                    id={`${company._id}-shop-zipCode-input`}
                    name="zipCode"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <label
                    id={`${company._id}-shop-city-label`}
                    htmlFor={`${company._id}-shop-city-input`}
                  >
                    Ville
                  </label>
                  <input
                    id={`${company._id}-shop-city-input`}
                    name="city"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-12 mt-1 padding-1">
                <ScheduleDeliveryCheckboxes
                  id={company._id}
                  onChange={handleScheduleChange}
                />
                <hr />
                <ProductTypesCheckboxes
                  id={company._id}
                  onChange={handleProductsChange}
                />
                <button className="button-s" disabled={isSumitDisbabled()}>
                  Ajouter
                </button>
              </div>
            </div>
          </form>
        </div>
        {/* </details> */}
      </Modal>
    </>
  );
}
