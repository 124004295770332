import { useEffect } from "react";
import { useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { translate } from "../translation";
import { formatComments, formatDate } from "../utils";
import AlertDialog from "./AlertDialog";

export default function Works() {
    const { fetchData } = useAuth();
    const [products, setProducts] = useState(null);

    const loadProducts = async () => {
        let p = await fetchData({ endpoint: 'products/todo' });
        setProducts(p);
    };

    useEffect(() => {
        loadProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleValidateWork = async (product) => {
        await fetchData({ endpoint: `products/update/${product._id}`, method: 'PUT', data: {} });
        await loadProducts();
    }

    console.debug("products", products)
    return (
        <div className="col-12 padding-2 justify-center align-center">
            <div className="col-12">
                {
                    products && products.map(p => (
                        <div key={p._id} className="row  bg-white color-primary padding-1 br-4 ">
                            <div className="row w-100 justify-between">
                                <h4>
                                    {formatDate(p.deliveryDate)}
                                </h4>
                                <h4 className="bold">
                                    {p.reference}
                                    {" - "}
                                    {translate(p.type)}
                                </h4>
                                <AlertDialog buttonText={"Valider"} onCancel={() => { }} onAccept={() => handleValidateWork(p)} title={"Confirmation"} message={"Voulez-vous valider cette prestation ?"} />
                            </div>
                            <hr className="w-100 mt-1 mb-1" />
                            <div className="col-6">
                                <p className="bold">Prestation</p>
                                <ul>
                                    {p.work && <li>{translate(p.work)}</li>}
                                    {p.rubber && <li>{p.rubber}</li>}
                                    {p.thickness && <li>{p.thickness}</li>}
                                </ul>
                            </div>
                            <div className="col-6">
                                <p className="bold">Commentaires</p>
                                <ul>{formatComments(p.comments)}</ul>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}