import React from "react";
import style from "./ShopAdminSummary.module.scss";
import { ChevronRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

export default function ShopAdminSummary({ shop }) {
  let pending = shop.requests.filter(
    (request) => request.state === "PENDING"
  ).length;
  let collected = shop.requests.filter(
    (request) => request.state === "COLLECTED"
  ).length;

  if (!collected && !pending) return null;

  return (
    <Link to={`shops/${shop._id}`} className={style["summary"]}>
      <div className={style["header"]}>
        <h2>{`${shop.name}`}</h2>
      </div>
      <div className={style["content-line"]}>
        <div className={style["pending"]}>
          À collecter: <strong>{pending}</strong>
        </div>
        <div className={style["collected"]}>
          À réparer : <strong>{collected}</strong>
        </div>
      </div>
      <div className={style["arrow-container"]}>
        <ChevronRight className={style["arrow"]} />
      </div>
    </Link>
  );
}
