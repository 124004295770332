import { useState } from "react";
// import { Calendar } from "react-calendar";
import Calendar from "./Calendar";


export default function DirectDateSelection({ address, minDate, onCollectChange, onDeliveryChange }) {

    const [selectedCollect, setSelectedCollect] = useState(null);
    // const [selectedDelivery, setSelectedDelivery] = useState(null);

    const handleCollectChange = (value) => {
        setSelectedCollect(value);
        onCollectChange(value);
    }

    const handleDeliveryChange = (value) => {
        // setSelectedDelivery(value);
        onDeliveryChange(value);
    }

    return (
        <div className="row mb-1">
            <div className="col-6">
                <h5 className="bold mt-1">Collecte</h5>
                <div className="row">
                    <Calendar address={address} onSelected={handleCollectChange} />
                    {/* <select onChange={handleCollectChange}>
                        <option value={null}>Selectionner un créneau</option>
                        {
                            collect &&
                            collect.map(day => (
                                day && day.slots && day.slots.map(slot => (
                                    <option value={slot.from}>
                                        {formatDate(new Date(day.date))}
                                        {" de "}
                                        {formatSlot(new Date(slot.from))}
                                        {" à "}
                                        {formatSlot(new Date(slot.to))}
                                    </option>
                                ))
                            )).flat()
                        }
                    </select> */}
                </div>
            </div>

            <div className="col-6">
                <h5 className="bold mt-1">Livraison</h5>
                <div className="row">
                    <Calendar type="delivery" address={address} onSelected={handleDeliveryChange} minDate={selectedCollect || new Date()} />
                    {/* <select onChange={handleDeliveryChange}>
                        <option>Selectionner un créneau</option>
                        {
                            delivery &&
                            delivery.map(day => (
                                day && day.slots && day.slots.map(slot => (
                                    <option value={slot.from}>
                                        {formatDate(new Date(day.date))}
                                        {" de "}
                                        {formatSlot(new Date(slot.from))}
                                        {" à "}
                                        {formatSlot(new Date(slot.to))}
                                    </option>
                                ))
                            )).flat()
                        }
                    </select> */}
                </div>
            </div>
        </div>


    )
}