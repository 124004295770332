import React, { useEffect, useState } from "react";
import style from "./PageLayout.module.scss";
import ScrollToTopButton from "./ScrollToTopButton";

export default function PageLayout({ children }) {
  const [showTopButton, setShowTopButton] = useState(false);

  useEffect(() => {
    function handleScrollButtonvisibility() {
      setShowTopButton(window.scrollY > 200);
    }

    window.addEventListener("scroll", handleScrollButtonvisibility);

    return () => {
      window.removeEventListener("scroll", handleScrollButtonvisibility);
    };
  }, []);

  return (
    <div className={style.layout}>
      {children}
      {showTopButton && <ScrollToTopButton />}
    </div>
  );
}
