import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import RequestForm from "../../components/RequestForm";
import RequestList from "../../components/RequestList";
import ShopPageHeader from "../../components/ShopPageHeader";
import { useAuth } from "../../hooks/useAuth";

export default function ShopPage() {
  const { shopId } = useParams();
  const { fetchData } = useAuth();
  const [shop, setShop] = useState(null);

  const loadShop = async () => {
    let s = await fetchData({ endpoint: `shops/${shopId ? shopId : 0}` });
    setShop(s);
  };

  const deleteRequest = async (shopId, requestId) => {
    let response = await fetchData({
      endpoint: `shop/${shopId}/delete-request/${requestId}`,
      method: "DELETE",
    });
    setShop(response);
  };

  const collectRequest = async (shopId, requestId) => {
    let response = await fetchData({
      endpoint: `shop/${shopId}/collect-request/${requestId}`,
      method: "PUT",
    });
    setShop(response);
  };

  function handleDeleteRequest(requestId) {
    if (
      window.confirm(
        "Voulez-vous supprimer cet article ? Cette action est irréversible."
      )
    ) {
      deleteRequest(shop._id, requestId);
    }
  }

  function handleEditRequest(requestId) {
    loadShop(shop._id);
  }

  function handleCollect(requestId) {
    collectRequest(shop._id, requestId);
  }

  useEffect(() => {
    loadShop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopId]);

  return (
    <>
      <PageLayout>
        {shop && (
          <>
            <ShopPageHeader shop={shop} displayContact={false} />
            <RequestForm shop={shop} onSubmit={() => loadShop(shopId)} />
            <RequestList
              shop={shop}
              title="Articles en attente de collecte"
              requests={shop.requests.filter((r) => r.state === "PENDING")}
              onDelete={handleDeleteRequest}
              onEdit={(requestId) => handleEditRequest(requestId)}
              onUpdateState={(requestId) => handleCollect(requestId)}
            />
            <RequestList
              shop={shop}
              title="Articles en réparation"
              requests={shop.requests.filter((r) => r.state === "COLLECTED")}
              onDelete={handleDeleteRequest}
              onEdit={(requestId) => handleEditRequest(requestId)}
              // onUpdateState={(requestId) => handleDeliver(requestId)}
              // onRevertState={(requestId) => handleRevert(requestId)}
            />
            {shop._id && (
              <Link to={`history/${shop._id}`}>Voir commandes terminées</Link>
            )}
          </>
        )}
      </PageLayout>
    </>
  );
}
