import React from "react";
import { Outlet } from "react-router-dom";
import LogisticNav from "../../components/LogisticNav";

export default function LogisticRootPage() {
    return (
        <>
            <LogisticNav />
            <Outlet />
        </>
    )
}