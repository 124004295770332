import React from "react";
import style from "./CompaniesList.module.scss";

export default function CompaniesList({ children }) {
  return (
    <div className={style["companies-list"]}>
      {children}
      {/* {companies &&
        companies.map((company) => (
          <div key={`companies-list-${company._id}`}>
            <a href={`#${company._id}`}>{company.name}</a>
          </div>
        ))} */}
    </div>
  );
}
