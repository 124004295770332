import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { authenticate } from "../services/authentication";
import { useLocalStorage } from "./useLocalStorage";

const API_URL = process.env.REACT_APP_API_URL;

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);

  const navigate = useNavigate();

  const fetchData = async ({ endpoint, method, data, isBlob = false }) => {
    const headers = new Headers({
      "authorization": `Bearer ${user.accessToken}`,
      "x-refresh": user.refreshToken,
      "Content-Type": "application/json"
    });

    try {
      const response = await fetch(`${API_URL}/${user.role}/${endpoint}`, {
        method: method || "GET",
        headers: headers,
        body: data && JSON.stringify(data)
      });
      if (response.status === 403) {
        navigate("/", { replace: true });
        return null;
      }
      if (response.status !== 200) {
        return null;
      }
      return isBlob
        ? await response.blob()
        : await response.json()
    } catch (error) {
      return null;
    }
  }

  const login = async (data) => {
    const response = await authenticate(data)
    setUser(response);

    if (!response) {
      navigate("/", { replace: true });
      return;
    }

    switch (response.role) {
      case "admin":
        navigate("/admin");
        break;
      case "company":
        navigate("/company");
        break;
      case "shop":
        navigate("/shop");
        break;
      case "logistic":
        navigate("/logistic");
        break;
        case "workshop":
          navigate("/workshop");
          break;
      default:
        navigate("/")
        break;
    }
  };

  const logout = () => {
    setUser(null);
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      fetchData
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [user]
  );


  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
export default AuthProvider;
