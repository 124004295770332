import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { translate } from "../../translation";
import { formatComments, formatDate, printState } from "../../utils";

export default function CollectionsPage() {
    const { fetchData } = useAuth();
    const [orders, setOrders] = useState(null);

    const loadOrders = async () => {
        const s = await fetchData({ endpoint: "collections" });
        setOrders(s);
    }

    useEffect(() => {
        loadOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleValidateCollection = async (shopName, date, orderId) => {
        let chrono = await fetchData({ endpoint: `chronopost/${orderId}/collection`, isBlob: true });
        let blobUrl = window.URL.createObjectURL(chrono);
        let a = document.createElement('a');
        
        a.href = blobUrl;
        a.target = "_blank";
        a.download = `${shopName}-${date}.txt`;
        document.body.appendChild(a);
        a.click();
        a.remove();

        let iframe = document.createElement('iframe'); //load content in an iframe to print later
        document.body.appendChild(iframe);

        iframe.style.display = 'none';
        iframe.src = blobUrl;
        iframe.onload = function () {
            setTimeout(function () {
                iframe.focus();
                iframe.contentWindow.print();
            }, 1);
        };
    }

    return (
        <div className="col-12 padding-2 justify-center align-center">
            <div className="row justify-between w-100 bg-primary br-4 padding-1">
                <div className="col-12">
                    {
                        orders && orders.map(order => (
                            <details key={order._id}>
                                <summary className="color-white">
                                    <div className="row w-100 justify-between">
                                        {
                                            order.state === "PENDING"
                                                ? <p className="mb-0">{`Nouvelle commande`}</p>
                                                : <p className="mb-0">{`Enlèvement ${formatDate(new Date(order.collectionDate))}`}</p>
                                        }

                                        <p className="mb-0">{printState(order.state)}</p>
                                    </div>
                                </summary>
                                <div className="padding-1 row bg-light">
                                    <div className="col-8">
                                        <div className="col-10">
                                            {
                                                order.products.map(p => (
                                                    <div key={p._id} className="row">
                                                        <div className="col-6">
                                                            <p className="bold">
                                                                {p.reference}
                                                                {" - "}
                                                                {translate(p.type)}
                                                            </p>
                                                        </div>
                                                        <div className="col-12">
                                                            {formatComments(p.comments, p.work)}
                                                        </div>
                                                        <div className="col-12">
                                                            <hr className="mt-1 mb-1" />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <button className="button-s" onClick={() => handleValidateCollection(order.shop.name, formatDate(new Date(order.collectionDate)), order._id)}>Télécharger étiquette</button>
                                    </div>
                                </div>
                            </details>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}