import React, { useEffect, useState } from "react";
import ShopAdminSummary from "../../components/ShopAdminSummary";
import { useAuth } from "../../hooks/useAuth";

export default function OrdersPage() {
  const { fetchData } = useAuth();

  const [shops, setShops] = useState(null);

  const loadShops = async () => {
    let o = await fetchData({ endpoint: `shops` });
    setShops(o);
  };

  useEffect(() => {
    loadShops();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="col-12 flex-1 align-center bg-secondary h-100">
      <div className="col-8 mt-2">
        {shops &&
          shops
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((shop) => {
              return <ShopAdminSummary key={shop._id} shop={shop} />;
            })}
      </div>
    </div>
  );
}
