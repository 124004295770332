import React, { useState } from "react";
import Modal from "react-modal";
import { formatRawScheduleToStringArray } from "../utils";
import ChronopostShipping from "./ChronopostShipping";
import style from "./CompanySummary.module.scss";
import ShopCreationForm from "./ShopCreationForm";
import ShopUpdateForm from "./ShopUpdateForm";

export default function CompanySummary({ company, onUpdate }) {
  const [modalOpen, setModalOpen] = useState(false);

  function closeModal() {
    setModalOpen(false);
  }

  function openModal() {
    setModalOpen(true);
  }

  function handleClick(event) {
    event.preventDefault();
    openModal();
  }

  return (
    <>
      <button
        className={`button-s ${style["modal-button"]}`}
        onClick={handleClick}
      >
        {company.name}
      </button>
      <Modal
        className={style["modal"]}
        isOpen={modalOpen}
        onRequestClose={closeModal}
        shouldCloseOnEsc={true}
        overlayClassName={style["modal-overlay"]}
        shouldCloseOnOverlayClick={true}
      >
        <div id={company._id} className={style["summary"]}>
          {/* <div className={style["header"]}>
            <h1>
              <strong>{company.name}</strong>
            </h1>
          </div> */}
          <div className={style["shop-list"]}>
            <div>
              <ShopCreationForm
                company={company}
                onSubmit={() => {
                  setModalOpen(false);
                  onUpdate();
                }}
              />
            </div>
            {/* <button className={`button-s`}>
              <PlusCircleFill />
            </button> */}
            {company.shops &&
              company.shops.map((shop) => (
                <div key={`shop-${shop._id}`} className={style["shop-summary"]}>
                  <div className={style["shop-header"]}>
                    <div>
                      <ShopUpdateForm
                        id={shop._id}
                        companyId={company._id}
                        onSubmit={onUpdate}
                      />
                      {/* <a
                        href={`companies/${company._id}/shop/form?id=${shop._id}`}
                      >
                        <PencilFill />
                      </a> */}
                    </div>
                    <a href={`shops/${shop._id}`}>
                      <h2>
                        <strong className={style["reference"]}>
                          {shop.parentReference}-{shop.reference} {shop.name}
                        </strong>
                      </h2>
                    </a>
                  </div>
                  <div className={style["shop-info"]}>
                    <div className={style["shop-contact"]}>
                      <div className={style["contact-line"]}>
                        {shop.contactName}
                      </div>
                      <div className={style["contact-line"]}>
                        <a href={`mailto:${shop.contactEmail}`}>
                          {shop.contactEmail}
                        </a>
                      </div>
                      <div className={style["contact-line"]}>
                        <a href={`tel:${shop.contactPhone}`}>
                          {shop.contactPhone}
                        </a>
                      </div>
                      <div className={style["contact-line"]}>
                        {shop.address} {shop.zipCode} {shop.city}
                      </div>
                    </div>

                    <div className={style["shop-delivery"]}>
                      <div className={style["delivery-line"]}>
                        {/* Passages :{" "} */}
                        {formatRawScheduleToStringArray(shop.schedule).map(
                          (s) => (
                            <div key={s}>{s}</div>
                          )
                        )}
                      </div>
                      <div>
                        <ChronopostShipping
                          shop={shop}
                          type="delivery"
                          text="Télécharger collecte Chronopost"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Modal>
    </>
  );
}
