import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth"

export default function AdminCompanySelect({ onChange }) {
    const { fetchData } = useAuth();
    const [companies, setCompanies] = useState(null);
    const [value, setValue] = useState("");

    const loadCompanies = async () => {
        let s = await fetchData({ endpoint: "companies" });
        setCompanies(s);
    };

    useEffect(() => {
        loadCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = ({ target }) => {
        const { value } = target;
        setValue(value);
        onChange({ target });
    }

    return (
        <div className="col-12">
            <label className="color-white" id="companies-label" htmlFor="companies-select">Entreprise</label>
            <select id="companies-select" name="companies" value={value} onChange={handleChange}>
            <option value={""}>Sélectionnez une entreprise</option>
                {
                    companies ?
                    
                    companies.map(shop => (
                        <option value={shop._id}>{shop.name}</option>
                    )) :
                    <option value={""}>Chargement des entreprises...</option>
                }
            </select>
        </div>
    )
}