import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import RequestForm from "../../components/RequestForm";
import RequestList from "../../components/RequestList";
import ShopPageHeader from "../../components/ShopPageHeader";
import { useAuth } from "../../hooks/useAuth";

export default function AdminShopPage() {
  const { shopId } = useParams();
  const { fetchData, user } = useAuth();

  const [shop, setShop] = useState(null);

  const loadShop = async (shopId) => {
    let response = await fetchData({ endpoint: `shops/${shopId}` });
    setShop(response);
  };

  const deleteRequest = async (shopId, requestId) => {
    let response = await fetchData({
      endpoint:
        user.role === "admin"
          ? `shop/${shopId}/delete-request/${requestId}`
          : `${shopId}/delete-request/${requestId}`,
      method: "DELETE",
    });
    setShop(response);
  };

  const collectRequest = async (shopId, requestId) => {
    let response = await fetchData({
      endpoint:
        user.role === "admin"
          ? `shop/${shopId}/collect-request/${requestId}`
          : `${shopId}/collect-request/${requestId}`,
      method: "PUT",
    });
    setShop(response);
  };

  const deliverRequest = async (shopId, requestId) => {
    let response = await fetchData({
      endpoint:
        user.role === "admin"
          ? `shop/${shopId}/deliver-request/${requestId}`
          : `${shopId}/deliver-request/${requestId}`,
      method: "PUT",
    });
    setShop(response);
  };

  const revertRequest = async (shopId, requestId) => {
    let response = await fetchData({
      endpoint:
        user.role === "admin"
          ? `shop/${shopId}/pending-request/${requestId}`
          : `${shopId}/pending-request/${requestId}`,
      method: "PUT",
    });
    setShop(response);
  };

  function handleDeleteRequest(requestId) {
    if (
      window.confirm(
        "Voulez-vous supprimer cet article ? Cette action est irréversible."
      )
    ) {
      deleteRequest(shopId, requestId);
    }
  }

  function handleEditRequest(requestId) {
    loadShop(shopId);
  }

  function handleCollect(requestId) {
    collectRequest(shopId, requestId);
  }

  function handleDeliver(requestId) {
    deliverRequest(shopId, requestId);
  }

  function handleRevert(requestId) {
    revertRequest(shopId, requestId);
  }

  useEffect(() => {
    loadShop(shopId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopId]);

  return (
    <>
      <PageLayout>
        {shop && (
          <>
            <ShopPageHeader shop={shop} />
            <RequestForm shop={shop} onSubmit={() => loadShop(shopId)} />
            <RequestList
              isAdmin
              shop={shop}
              title="Articles en attente de collecte"
              requests={shop.requests.filter((r) => r.state === "PENDING")}
              onDelete={handleDeleteRequest}
              onEdit={(requestId) => handleEditRequest(requestId)}
              onUpdateState={(requestId) => handleCollect(requestId)}
            />
            <RequestList
              isAdmin
              shop={shop}
              title="Articles en réparation"
              requests={shop.requests.filter((r) => r.state === "COLLECTED")}
              onDelete={handleDeleteRequest}
              onEdit={(requestId) => handleEditRequest(requestId)}
              onUpdateState={(requestId) => handleDeliver(requestId)}
              onRevertState={(requestId) => handleRevert(requestId)}
            />
            <Link to="history">Voir commandes terminées</Link>
          </>
        )}
      </PageLayout>
    </>
  );
}
