import { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { translate } from "../translation";
import Checkbox from "./Checkbox";

const DEFAULT_PRODUCT = {
  type: "",
  comments: "",
  reference: "",
  work: "",
  works: [],
};

const MAN_SHOES_WORKS = [
  { name: "Patins H", checked: false },
  { name: "Talons H", checked: false },
  { name: "Ressemelage cuir", checked: false },
  { name: "Fer", checked: false },
  { name: "Soin / Cirage", checked: false },
];

const WOMAN_SHOES_WORKS = [
  { name: "Patins F", checked: false },
  { name: "Talons F", checked: false },
  { name: "Enveloppes", checked: false },
  { name: "Soin / Cirage", checked: false },
];

const SNEAKERS_SHOES_WORKS = [
  { name: "Ressemelage", checked: false },
  { name: "Soin / Nettoyage", checked: false },
];

const CLIMBING_SHOES_WORKS = {
  ressemelage: [
    {
      name: "Edge (Dur)",
      sizes: ["4mm", "5mm"],
    },
    {
      name: "Grip (Medium)",
      sizes: ["4mm", "5mm"],
    },
    {
      name: "Grip-2 (Souple)",
      sizes: ["4mm"],
    },
  ],
  enrobage: [
    {
      name: "Edge (Dur)",
      sizes: ["4mm", "5mm"],
    },
    {
      name: "Grip (Medium)",
      sizes: ["4mm", "5mm"],
    },
    {
      name: "Grip-2 (Souple)",
      sizes: ["4mm"],
    },
  ],
};

export default function OrderCreationForm({ onSubmit }) {
  const { shopId } = useParams();
  const { fetchData } = useAuth();
  const [shop, setShop] = useState(null);
  // const [products, setProducts] = useState([]);
  const [newProduct, setNewProduct] = useState(DEFAULT_PRODUCT);
  const [orderId] = useState(null);
  const [works, setWorks] = useState([]);

  const productFormIsValid = (pf) => {
    if (pf.type === "climbing_shoes") {
      return (
        pf.work !== DEFAULT_PRODUCT.work &&
        pf.reference !== DEFAULT_PRODUCT.reference &&
        pf.rubber &&
        pf.thickness
      );
    } else {
      return (
        (works.filter((w) => w.checked).length > 0 || pf.comments.length > 0) &&
        pf.reference !== DEFAULT_PRODUCT.reference
      );
    }
    // return pf.type !== DEFAULT_PRODUCT.type && pf.comments !== DEFAULT_PRODUCT.comments && pf.reference !== DEFAULT_PRODUCT.reference;
  };

  const loadShop = async () => {
    if (!shopId) return;
    const s = await fetchData({ endpoint: `shops/${shopId}` });
    setShop(s);
  };

  useEffect(() => {
    loadShop();
    // loadOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopId]);

  const handleNewProductChange = ({ target }) => {
    const { name, value } = target;
    setNewProduct({ ...newProduct, [name]: value });
  };

  const handleProductTypeChange = ({ target }) => {
    const { name, value } = target;
    setNewProduct({ ...newProduct, [name]: value });
    switch (value) {
      case "man_shoes":
        setWorks(MAN_SHOES_WORKS);
        break;
      case "woman_shoes":
        setWorks(WOMAN_SHOES_WORKS);
        break;
      case "sneakers":
        setWorks(SNEAKERS_SHOES_WORKS);
        break;
      default:
        break;
    }
  };
  const handleAddNewProduct = async (event) => {
    event.preventDefault();
    let productToAdd = { ...newProduct };
    if (newProduct.type !== "climbing_shoes") {
      productToAdd.works = works.filter((w) => w.checked).map((w) => w.name);
    }
    // let p = [...products, productToAdd];
    // setProducts(p);
    // setNewProduct(DEFAULT_PRODUCT);

    let endpoint = "order";
    if (orderId) {
      endpoint = `shop/${shopId}/add-request`;
    }
    await fetchData({
      endpoint: endpoint,
      method: "POST",
      data: { ...productToAdd },
    });

    onSubmit && onSubmit();
  };

  //   const handleDeleteProduct = async (index) => {
  //     let tmp = [...products];
  //     tmp.splice(index, 1);

  //     let endpoint = "order";
  //     if (orderId) {
  //       endpoint = `order/${orderId}`;
  //     }
  //     await fetchData({
  //       endpoint: endpoint,
  //       method: "POST",
  //       data: { shop: shopId, products: tmp },
  //     });

  //     setProducts(tmp);
  //   };

  //   const handleScheduleCollect = async (e) => {
  //     e.preventDefault();
  //     await fetchData({
  //       endpoint: `shops/${shopId}/orders/${orderId}/validate`,
  //       method: "GET",
  //     });
  //     navigate(-1);
  //   };

  //   const handleBackClick = (e) => {
  //     e.preventDefault();
  //     navigate(-1);
  //   };

  const handleWorksCheckStatus = (index) => {
    console.debug("handler chexk", index, works);
    setWorks((currentWork) =>
      currentWork.map((currentWork, currentWorksIndex) =>
        currentWorksIndex === index
          ? { ...currentWork, checked: !currentWork.checked }
          : currentWork
      )
    );
  };

  //   const renderProduct = (p, i) => {
  //     if (p.type === "climbing_shoes") {
  //       return (
  //         <div key={`product-${i}`} className="row color-white">
  //           <div className="col-3">
  //             <h5>Numéro ticket</h5>
  //             <p className="bold">{p.reference.toString().padStart(3, "0")}</p>
  //           </div>
  //           <div className="col-6">
  //             <h5>Commentaires</h5>
  //             <p className="bold">{p.comments}</p>
  //           </div>
  //           <div className="col-3 ml-auto">
  //             <AlertDialog
  //               buttonText={"Supprimer"}
  //               onCancel={() => {}}
  //               onAccept={() => handleDeleteProduct(i)}
  //               title={"Supprimer ?"}
  //               message={"Etes-vous sûr de vouloir supprimer cet article ?"}
  //             />
  //           </div>
  //           <div className="col-3">
  //             <h5>Type</h5>
  //             <p className="bold">{translate(p.type)}</p>
  //           </div>
  //           <div className="col-3">
  //             <h5>Prestations</h5>
  //             <p className="bold">{p.work}</p>
  //           </div>
  //           <div className="col-3">
  //             <h5>Gomme</h5>
  //             <p className="bold">{p.rubber}</p>
  //           </div>
  //           <div className="col-3">
  //             <h5>Epaisseur</h5>
  //             <p className="bold">{p.thickness}</p>
  //           </div>
  //         </div>
  //       );
  //     }
  //     return (
  //       <div key={`product-${i}`} className="row color-white">
  //         <div className="col-3">
  //           <h5>Numéro ticket</h5>
  //           <p className="bold">{p.reference.toString().padStart(3, "0")}</p>
  //         </div>
  //         <div className="col-3">
  //           <h5>Type</h5>
  //           <p className="bold">{translate(p.type)}</p>
  //         </div>
  //         <div className="col-3">
  //           <h5>Prestations</h5>
  //           <ul>
  //             {p.works && p.works.map((w, i) => <li key={"work-" + i}>{w}</li>)}
  //           </ul>
  //         </div>
  //         <div className="col-3">
  //           <h5>Commentaires</h5>
  //           <p className="bold">{p.comments}</p>
  //         </div>
  //         <div className="col-3">
  //           <AlertDialog
  //             buttonText={"Supprimer"}
  //             onCancel={() => {}}
  //             onAccept={() => handleDeleteProduct(i)}
  //             title={"Supprimer ?"}
  //             message={"Etes-vous sûr de vouloir supprimer cet article ?"}
  //           />
  //           {/* <button type="button" className="button-s" onClick={(e) => handleDeleteProduct(e, i)}>Supprimer</button> */}
  //         </div>
  //       </div>
  //     );
  //   };

  const renderWorkSelection = () => {
    if (newProduct.type === "climbing_shoes") {
      return (
        <div className="row w-100 mt-1 justify-between">
          <div className="col-3 pl-1 pr-1">
            <label id={``} htmlFor={``}>
              Prestation
            </label>
            <select
              id={``}
              value={newProduct.work}
              name={`work`}
              onChange={handleNewProductChange}
            >
              <option value="">Sélectionnez une prestation</option>
              <option value="ressemelage">Ressemelage</option>
              <option value="enrobage">Ressemelage et enrobage</option>
            </select>
          </div>

          {newProduct.work && (
            <div className="col-3 pl-1 pr-1">
              <label id={``} htmlFor={``}>
                Gomme
              </label>
              <select
                id={``}
                value={newProduct.rubber}
                name={`rubber`}
                onChange={handleNewProductChange}
              >
                <option value="">Sélectionnez une gomme</option>
                {CLIMBING_SHOES_WORKS[newProduct.work].map((rubber) => (
                  <option key={rubber.name} value={rubber.name}>
                    {rubber.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {newProduct.rubber && (
            <div className="col-3 pl-1 pr-1">
              <label id={``} htmlFor={``}>
                Epaisseur
              </label>
              <select
                id={``}
                value={newProduct.thickness}
                name={`thickness`}
                onChange={handleNewProductChange}
              >
                <option value="">Sélectionnez une épaisseur</option>
                {CLIMBING_SHOES_WORKS[newProduct.work]
                  .find((w) => w.name === newProduct.rubber)
                  .sizes.map((size) => (
                    <option key={newProduct.rubber + "-" + size} value={size}>
                      {size}
                    </option>
                  ))}
              </select>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="row w-100 mt-1 justify-between">
          <div className="col-3 pl-1 pr-1">
            <label id={``} htmlFor={``}>
              Prestations
            </label>
            {works.map((work, workIndex) => (
              <Checkbox
                index={workIndex}
                label={work.name}
                isChecked={work.checked}
                onCheck={() => handleWorksCheckStatus(workIndex)}
              />
            ))}
          </div>
        </div>
      );
    }
  };

  if (!shop) return null;

  return (
    <div className="col-12">
      {/* <div className="col-12 padding-1 br-4 bg-primary"> */}
      {/* <h3 className="color-white bold mb-1">Nouvelle commande</h3> */}
      <form>
        <div className="col-12">
          {/* {
                            products && products.map((p, i) => (
                                <div key={i}>
                                    {renderProduct(p, i)}
                                    <hr />
                                </div>
                            ))
                        } */}
          <div className="bg-white padding-1 br-4">
            <h4 className="bold mt-0">Nouvel article</h4>
            <div className="row w-100 align-center justify-between">
              <div className="col-3 pl-1 pr-1">
                <label
                  id="product-new-reference-label"
                  htmlFor="product-new-reference-input"
                >
                  Numéro ticket
                </label>
                <input
                  type="text"
                  id="product-new-reference-input"
                  name="reference"
                  value={newProduct.reference}
                  onChange={handleNewProductChange}
                />
              </div>
              <div className="col-3 pl-1 pr-1">
                <label
                  id={`product-new-type-select-label`}
                  htmlFor={`product-new-type-select`}
                >
                  Type d'article
                </label>
                <select
                  id={`product-new-type-select`}
                  value={newProduct.type}
                  name={`type`}
                  onChange={handleProductTypeChange}
                >
                  <option value="">Sélectionnez un type</option>
                  {shop &&
                    shop.products &&
                    shop.products.map((p) => (
                      <option key={p} value={p}>
                        {translate(p)}
                      </option>
                    ))}
                </select>
              </div>

              <div className="col-3 pl-1 pr-1">
                <label
                  id="product-new-comments-label"
                  htmlFor="product-new-comments-input"
                >
                  Commentaires
                </label>
                <textarea
                  id="product-new-comments-input"
                  name="comments"
                  value={newProduct.comments}
                  onChange={handleNewProductChange}
                />
              </div>
            </div>

            <div className="row w-100 pt-1 align-center justify-between">
              <div className="col-3 pl-1 pr-1">
                <label
                  id="product-new-client-name-label"
                  htmlFor="product-new-client-name-input"
                >
                  Nom client
                </label>
                <input
                  id="product-new-client-name-input"
                  name="clientName"
                  value={newProduct.clientName}
                  onChange={handleNewProductChange}
                />
              </div>

              <div className="col-3 pl-1 pr-1">
                <label
                  id="product-new-client-phone-label"
                  htmlFor="product-new-client-phone-input"
                >
                  Téléphone client
                </label>
                <input
                  id="product-new-client-phone-input"
                  type="tel"
                  name="clientPhone"
                  value={newProduct.clientPhone}
                  onChange={handleNewProductChange}
                />
              </div>

              <div className="col-3 pl-1 pr-1">
                <label
                  id="product-new-client-name-label"
                  htmlFor="product-new-client-comment-input"
                >
                  Commentaire client
                </label>
                <input
                  id="product-new-client-comment-input"
                  type="text"
                  name="clientComment"
                  value={newProduct.clientComment}
                  onChange={handleNewProductChange}
                />
              </div>
            </div>

            {renderWorkSelection()}

            <div className="col-3 pl-1 pr-1 mt-1 align-self-end">
              <button
                disabled={!productFormIsValid(newProduct)}
                type="button"
                className="button-s"
                onClick={handleAddNewProduct}
              >
                Ajouter article
              </button>
            </div>
          </div>
          {/* <hr className="mt-2 mb-2" />
          <div className="row margin-1 mt-2 justify-between">
            <button
              className="button-l outlined-dark"
              onClick={handleBackClick}
            >
              Retour
            </button>
            <button className="button-l" onClick={handleScheduleCollect}>
              Programmer un enlèvement
            </button>
          </div> */}
        </div>
      </form>
      {/* </div > */}
    </div>
  );
}
