import { useState } from "react";
import { PencilFill } from "react-bootstrap-icons";
import Modal from "react-modal";
import { useAuth } from "../hooks/useAuth";
import ClimbingGumSelector from "./ClimbingGumSelector";
import ProductTypeSelector from "./ProductTypeSelector";
import style from "./RequestForm.module.scss";
import RequestInfoForm from "./RequestInfoForm";
import RequestValidate from "./RequestValidate";
import WorksSelector from "./WorksSelector";

export default function RequestForm({
  shop,
  onSubmit,
  isEdit = false,
  request,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const { fetchData, user } = useAuth();

  const [values, setValues] = useState(getInitialValues());

  function getInitialValues() {
    return {
      reference:
        isEdit && request
          ? request.reference?.toString()?.padStart(3, "0")
          : "",
      type: isEdit && request ? request.type : null,
      works: isEdit && request ? request.works : [],
      rubber: isEdit && request ? request.rubber : null,
      clientName: isEdit && request ? request.clientName : "",
      clientPhone: isEdit && request ? request.clientPhone : "",
      comments: isEdit && request ? request.comments : "",
      isValid: isEdit && request ? request.isValid : false,
    };
  }
  function openModal() {
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }

  function handleTypeChange(type) {
    let newValues = { ...values, type, works: [] };
    setValues({ ...newValues, isValid: isValid(newValues) });
  }

  function handleWorksChange(works) {
    let newValues = { ...values, works: works };
    setValues({ ...newValues, isValid: isValid(newValues) });
  }

  function handleRubberChange(rubber) {
    let newValues = { ...values, rubber };
    setValues({ ...newValues, isValid: isValid(newValues) });
  }

  function handleInfoChange(target) {
    const { name, value } = target;
    let newValues = { ...values, [name]: value };
    setValues({ ...newValues, isValid: isValid(newValues) });
  }

  function isValid(values) {
    const { type, rubber, works } = values;

    if (type === "climbing_shoes" && !rubber) {
      return false;
    }
    return type !== null && works.length > 0;
  }

  async function handleValidate(requestReference) {
    let data = { ...values, reference: requestReference };

    if (data.comments) {
      data.works.push();
    }

    delete data.isValid;
    if (isEdit) {
      await fetchData({
        endpoint:
          user.role === "admin"
            ? `shop/${shop._id}/edit-request/${request._id}`
            : `${shop._id}/edit-request/${request._id}`,
        method: "PUT",
        data,
      });
    } else {
      await fetchData({
        endpoint:
          user.role === "admin"
            ? `shop/${shop._id}/add-request`
            : `${shop._id}/add-request`,
        method: "POST",
        data,
      });
    }

    closeModal();
    onSubmit && onSubmit();
    setValues(getInitialValues());
  }

  return (
    <>
      <button
        className={`button-s ${style["modal-button"]} ${
          isEdit ? style["edit-button"] : ""
        }`}
        onClick={openModal}
      >
        {isEdit ? <PencilFill /> : "Ajouter un article"}
      </button>
      <Modal
        className={style["modal"]}
        buttonTitle={"Modifier"}
        isOpen={modalOpen}
        onRequestClose={closeModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        overlayClassName={style["modal-overlay"]}
      >
        <div className={style["modal-content"]}>
          <RequestInfoForm onChange={handleInfoChange} values={values} />

          <ProductTypeSelector
            types={shop.products}
            onSelected={handleTypeChange}
            value={values.type}
          />
          {values.type && (
            <WorksSelector
              type={values.type}
              onSelected={handleWorksChange}
              values={values.works}
            />
          )}
          {values.type === "climbing_shoes" && (
            <ClimbingGumSelector
              onSelected={handleRubberChange}
              value={values.rubber}
            />
          )}

          <RequestValidate
            value={values.reference}
            isEnabled={values.isValid}
            refPrefix={`${shop.parentReference}-${shop.reference}-`}
            onValidate={handleValidate}
          />
        </div>
      </Modal>
    </>
  );
}
