import { useState } from "react";
import style from "./ClimbingGumSelector.module.scss";

const GUM = [
  "Edge (dur) 4mm",
  "Edge (dur) 5mm",
  "Grip (moyen) 4mm",
  "Grip (moyen) 5mm",
  "Grip-2 (souple) 5mm",
];

export default function ClimbingGumSelector({ onSelected, value }) {
  const [selected, setSelected] = useState(value);

  function handleClick(gum) {
    setSelected(gum);
    onSelected && onSelected(gum);
  }

  return (
    <div className={style["root"]}>
      <h3>Gomme*</h3>
      <div className={style["container"]}>
        {GUM.map((gum, i) => (
          <button
            key={`gum-${i}`}
            className={`${style["selectable"]} ${
              selected === gum ? style["selected"] : ""
            }`}
            onClick={() => handleClick(gum)}
          >
            {gum}
          </button>
        ))}
      </div>
    </div>
  );
}
