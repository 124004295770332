import Works from "../../components/Works";

export default function WorksPage() {

    return (
        <div className="col-12 justify-center align-center padding-2 w-100">
            {
                <>
                    <div className="row justify-center align-center w-100 bg-primary br-4 padding-1">
                        <div className="col-8 color-white">
                            <Works />
                        </div>
                    </div>

                </>
            }

        </div>
    )
}