import React from "react";
import { Outlet } from "react-router-dom";
import WorkshopNav from "../../components/WorkshopNav";

export default function WorkshopRootPage() {
    return (
        <>
            <WorkshopNav />
            <Outlet />
        </>
    )
}