import React from "react";
import { Outlet } from "react-router-dom";
import CompanyNav from "../../components/CompanyNav";

export default function CompanyRootPage() {
    return (
        <>
            <CompanyNav />
            <Outlet />
        </>
    )
}