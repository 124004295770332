import style from "./RequestInfoForm.module.scss";

export default function RequestInfoForm({ onChange, values }) {
  function handleChange({ target }) {
    const { name, value } = target;
    onChange && onChange({ name, value });
  }

  return (
    <div className={style["root"]}>
      <h3>Informations</h3>
      <div className={style["container"]}>
        <div className={style["input-container"]}>
          <label>Nom Client</label>
          <input
            name="clientName"
            onChange={handleChange}
            value={values.clientName}
          />
        </div>

        <div className={style["input-container"]}>
          <label>Téléphone Client</label>
          <input
            name="clientPhone"
            type="tel"
            onChange={handleChange}
            value={values.clientPhone}
          />
        </div>

        <div className={style["input-container"]}>
          <label>Infos / Commentaires</label>
          <textarea
            name="comments"
            onChange={handleChange}
            value={values.comments}
          />
        </div>
      </div>
    </div>
  );
}
