import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { formatDate } from "../../utils";

export default function DeliveryPage() {
    const { fetchData } = useAuth();
    const [shops, setShops] = useState(null);

    const loadShops = async () => {
        const s = await fetchData({ endpoint: "deliveries" });
        setShops(s);
    }

    useEffect(() => {
        loadShops();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleValidateCollection = async (orderId) => {
        await fetchData({ endpoint: `order/${orderId}`, method: 'PUT', data: {state: "DELIVERED" } })
        window.location.reload(false);
    }

    return (
        <div className="col-12 padding-2 justify-center align-center">
            <div className="col-12">
                {
                    shops && shops.map(shop => (
                        <details key={shop._id}>
                            <summary className="color-white">
                                {shop.name}
                            </summary>
                            {
                                shop.orders.map(order => (
                                    <div className="padding-1 row">
                                        <div className="col-8">
                                            <p className="color-white">{`Livraison du ${formatDate(order.deliveryDate)}`}</p>
                                            <div >
                                                <p className="bold color-white">Articles:</p>
                                                {
                                                    order.products.map(product => (
                                                        <p className="color-white">{product.reference}</p>
                                                    ))
                                                }
                                            </div>
                                            <hr />
                                        </div>
                                        <div className="col-4">
                                            <button className="button-s" onClick={() => handleValidateCollection(order._id)}>Valider livraison</button>
                                        </div>
                                    </div>
                                ))
                            }
                        </details>
                    ))
                }
            </div>
        </div>
    )
}