import { useState } from "react";
import { useAuth } from "../hooks/useAuth";

const DATE_DIFF = 604800000 // 7 days;
const DAY_DIFF = 86400000;

const formatSlot = (start, end) => {
    return (start.getHours().toString().padStart(2, '0') + ":" + start.getMinutes().toString().padStart(2, '0') + " - " + end.getHours().toString().padStart(2, '0') + ":" + end.getMinutes().toString().padStart(2, '0'));
}

export default function Calendar({ type = "collect", address, minDate = new Date(), onSelected }) {
    let defaultDate = type === "collect" ? new Date(Date.now() + DAY_DIFF) : new Date(minDate.getTime() + DATE_DIFF);
    const [deliverySlots, setDeliverySlots] = useState(null);
    const { fetchData } = useAuth();
    const [dateValue, setDateValue] = useState("T");


    const loadDeliverySlots = async (date) => {
        const slots = await fetchData({ endpoint: `timeslots`, method: "POST", data: { address: address, date: date } })
        setDeliverySlots(slots);
    }


    const handleDateChange = async ({ target }) => {
        setDateValue(target.value);
        await loadDeliverySlots(new Date(target.value))
    }

    const handleSlotSelected = ({ target }) => {
        let v = new Date(target.value);
        let selectedSlotDateTime = new Date(dateValue);
        selectedSlotDateTime.setHours(v.getHours());
        selectedSlotDateTime.setMinutes(v.getMinutes());
        onSelected && onSelected(selectedSlotDateTime);
    }

    return (
        <div>
            <input type="date" min={defaultDate.toISOString().split("T")[0]} value={dateValue.split("T")[0]} name="date" onChange={handleDateChange} />
            <div>
                {
                    deliverySlots && deliverySlots.length > 1 &&
                    deliverySlots[0].slots &&
                    <select onChange={handleSlotSelected}>
                        <option value={null}>Sélectionnez un créneau</option>
                        {
                            deliverySlots[0].slots.filter(slot => slot.counter < 1).map(slot => (
                                <option value={slot.from}>{formatSlot(new Date(slot.from), new Date(slot.to))}</option>
                            ))
                        }
                    </select>
                }
            </div>
        </div>
    )
}