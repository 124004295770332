import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { translate } from "../translation";
import { formatComments, printState } from "../utils";

export default function DirectOrders({ shopId, filter }) {
    const { fetchData } = useAuth();

    const [orders, setOrders] = useState(null);

    const loadOrders = async () => {
        let o = await fetchData({ endpoint: `direct/all/${shopId}` });
        setOrders(o);
    }

    useEffect(() => {
        loadOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shopId]);
    const filteredOrders = () => {
        if (!filter)
            return orders.filter(o => o.state !== "DELIVERED");
        return orders.filter(o => o.state !== "DELIVERED" && o.products.some(p => p.reference.toString().includes(filter)));
    }

    return (
        <div className="col-12 padding-2 justify-center align-center">
            <div className="col-12">
                {
                    orders && filteredOrders().map(o => (
                        <details className="w-100" key={o._id}>
                            <summary className="color-white">
                                <div className="row w-100 justify-between">
                                    <p className="bold mb-0">{printState(o.state)}</p>
                                </div>
                            </summary>
                            <div className="row padding-1 bg-light">
                                <div className="col-10 padding-1">
                                    <div className="col-10">
                                        <h4 className="bold">{o.customer.givenName} {o.customer.familyName}</h4>
                                    </div>
                                    {
                                        o.products.map(p => (
                                            <div key={p._id} className="row">
                                                <div className="row justify-between pr-1 w-100">
                                                    <h4 className="bold">{p.reference}
                                                        {" - "}
                                                        {translate(p.type)}
                                                    </h4>
                                                    {
                                                        o.state === "COLLECTED" &&
                                                        <p>{p.state === "done" ? "Terminé" : "A faire"}</p>
                                                    }
                                                </div>
                                                <div className="col-6">
                                                    <p className="bold">Prestation</p>
                                                    <ul>
                                                        {p.work && <li>{p.work}</li>}
                                                    </ul>
                                                </div>
                                                <div className="col-6">
                                                    <p className="bold">Commentaires</p>
                                                    <ul>{formatComments(p.comments)}</ul>
                                                </div>
                                                <div className="col-12">
                                                    <hr className="mt-1 mb-1" />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>
                        </details>
                    ))
                }
            </div>
        </div>
    )
}