import { BoxArrowDown, BoxArrowUp, TrashFill } from "react-bootstrap-icons";
import { translate } from "../translation";
import RequestForm from "./RequestForm";
import style from "./RequestList.module.scss";
import Scanner from "./Scanner";

export default function RequestList({
  requests,
  title,
  shop,
  onDelete,
  onEdit,
  onUpdateState,
  onRevertState,
  filter,
  hideEmpty,
  isAdmin,
}) {
  function handleScanned(code, shop) {
    let request = shop.requests.find((req) => {
      let references =
        shop.parentReference.toString() +
        shop.reference.toString() +
        req.reference.toString().padStart(3, "0");
      return references === code;
    });

    if (request) {
      onUpdateState && onUpdateState(request._id);
    } else {
      for (
        let requestIndex = 0;
        requestIndex < shop.requests.length;
        requestIndex++
      ) {
        const req = shop.requests[requestIndex];
        let references =
          (shop.parentReference?.toString() || "NA") +
          (shop.reference?.toString() || "NA") +
          (req.reference?.toString()?.padStart(3, "0") || "NA");
        if (references === code.slice(0, code.length - 1)) {
          request = req;
        }
      }

      if (request) {
        onUpdateState && onUpdateState(request._id);
      } else {
        console.debug("'Request not found");
      }
    }
  }

  function filterRequests() {
    if (!requests) return null;
    if (!filter || filter.length < 1) return requests;
    return requests.filter(
      (request) =>
        request.reference.toString().padStart(3, "0").includes(filter) ||
        request.clientName.toString().includes(filter) ||
        request.clientPhone.toString().includes(filter)
    );
  }

  if (hideEmpty && !filterRequests()) return null;
  return (
    <div>
      <div className={style["header"]}>
        <h2>{title + ` (${requests.length})`}</h2>
        {onUpdateState && isAdmin && (
          <Scanner onScanned={(code) => handleScanned(code, shop)} />
        )}
      </div>
      {requests &&
        filterRequests().map((request, index) => (
          <div key={request._id}>
            <div
              className={`${style["request-line"]} ${
                index % 2 === 0 ? style["gray"] : ""
              }`}
            >
              <div className={`${style["request-col"]}`}>
                <div>
                  {request.collectDate &&
                    `Collecte - ${new Date(
                      request.collectDate
                    ).toLocaleDateString("fr")}`}
                </div>
                <div>
                  {request.deliveryDate &&
                    `Livraison - ${new Date(
                      request.deliveryDate
                    ).toLocaleDateString("fr")}`}
                </div>
              </div>
              <div className={`${style["request-col"]} ${style["client"]}`}>
                <div>
                  <strong>{request.clientName}</strong>
                </div>
                <div>{request.clientPhone}</div>
                <div>
                  {request.comments && (
                    <i>
                      {request.comments.split("\n").map((com) => (
                        <>
                          {com}
                          <br />
                        </>
                      ))}
                    </i>
                  )}
                </div>
              </div>

              <div className={`${style["request-col"]} ${style["stretch"]}`}>
                <div>
                  <strong>
                    {shop.parentReference}-{shop.reference}-
                    {request.reference.toString().padStart(3, "0")}
                  </strong>{" "}
                  {translate(request.type)}
                </div>
              </div>

              <div className={`${style["request-col"]} ${style["stretch"]}`}>
                {request.works.map((work) => (
                  <div key={work}>{`${work}${
                    request.rubber ? ` - ${request.rubber}` : ""
                  }`}</div>
                ))}
              </div>

              <div className={style["request-col"]}>
                {onDelete && (
                  <button
                    className={` button-s ${style["trash-button"]}`}
                    onClick={() => onDelete && onDelete(request._id)}
                  >
                    <TrashFill />
                  </button>
                )}

                {onEdit && (
                  <RequestForm
                    shop={shop}
                    onSubmit={() => onEdit && onEdit()}
                    isEdit
                    request={request}
                  />
                )}
                {onRevertState && isAdmin && (
                  <button
                    className={` button-s ${style["trash-button"]}`}
                    onClick={() => onRevertState && onRevertState(request._id)}
                  >
                    <BoxArrowUp />
                  </button>
                )}
                {onUpdateState && isAdmin && (
                  <button
                    className={` button-s ${style["trash-button"]}`}
                    onClick={() => onUpdateState && onUpdateState(request._id)}
                  >
                    <BoxArrowDown />
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}
