import React, { useCallback, useState } from "react";
import { UpcScan } from "react-bootstrap-icons";
import Modal from "react-modal";
import style from "./Scanner.module.scss";

const customStyles = {
  content: {
    height: 0,
    width: 0,
    padding: 0,
    border: "none",
  },
};

let code = "";

export default function Scanner({ onScanned }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  function handleOpen() {
    setModalIsOpen(true);
  }

  function handleClose() {
    setModalIsOpen(false);
  }

  const handleKeyDown = useCallback((event) => {
    if (event.keyCode === 16) {
      return;
    }
    if (event.keyCode === 13) {
      if (code.startsWith("20")) {
        code = code.slice(2);
      }

      console.debug("Scan: ", code);
      onScanned && onScanned(code);
      code = "";
      return;
    }
    code = code + event.key;
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <button
        className={`button-s ${style["scan-button"]}`}
        onClick={handleOpen}
      >
        <UpcScan className={style["scan-icon"]} />
      </button>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={() => window.addEventListener("keydown", handleKeyDown)}
        onAfterClose={() =>
          window.removeEventListener("keydown", handleKeyDown)
        }
        onRequestClose={handleClose}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
        contentLabel="Example Modal"
        className={style["modal"]}
        overlayClassName={style["overlay"]}
      ></Modal>
    </div>
  );
}
