import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth"

export default function AdminShopSelect({ onChange }) {
    const { fetchData } = useAuth();
    const [shops, setShops] = useState(null);
    const [value, setValue] = useState("");

    const loadShops = async () => {
        let s = await fetchData({ endpoint: "shops" });
        setShops(s);
    };

    useEffect(() => {
        loadShops();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = ({ target }) => {
        const { value } = target;
        setValue(value);
        onChange({ target });
    }

    return (
        <div className="col-12">
            <label className="color-white" id="shops-label" htmlFor="shops-select">Boutique</label>
            <select id="shops-select" name="shops" value={value} onChange={handleChange}>
            <option value={""}>Sélectionnez une boutique</option>
                {
                    shops ?
                    shops.map(shop => (
                        <option value={shop._id}>{shop.name}</option>
                    )) :
                    <option value={""}>Chargement des boutiques...</option>
                }
            </select>
        </div>
    )
}