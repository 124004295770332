import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { translate } from "../translation";
import Checkbox from "./Checkbox";

const WORKS = {
    "man_shoes": [
        { name: "Patins H", checked: false },
        { name: "Talons H", checked: false },
        { name: "Ressemelage cuir", checked: false },
        { name: "Soin / Cirage", checked: false },
    ],
    "woman_shoes": [
        { name: "Patins F", checked: false },
        { name: "Talons F", checked: false },
        { name: "Enveloppes", checked: false },
        { name: "Soin / Cirage", checked: false },
    ],
    "sneakers": [
        { name: "Ressemelage", checked: false },
        { name: "Soin / Nettoyage", checked: false },
    ]
};

// const MAN_SHOES_WORKS = [
//     "Patins H",
//     "Talons H",
//     "Ressemelage cuir",
//     "Soin / Cirage",
//     "Autre"
// ];

// const WOMAN_SHOES_WORKS = [
//     "Patins F",
//     "Talons F",
//     "Enveloppes",
//     "Soin / Cirage",
//     "Autre"
// ];

// const SNEAKERS_SHOES_WORKS = [
//     "Ressemelage",
//     "Soin / Nettoyage",
//     "Autre"
// ];

// const CLIMBING_SHOES_WORKS = {
//     ressemelage: [
//         {
//             name: "Edge (Dur)",
//             sizes: ["4mm", "5mm"]
//         },
//         {
//             name: "Grip (Medium)",
//             sizes: ["4mm", "5mm"]
//         },
//         {
//             name: "Grip-2 (Souple)",
//             sizes: ["4mm"]
//         },
//     ],
//     enrobage: [
//         {
//             name: "Edge (Dur)",
//             sizes: ["4mm", "5mm"]
//         },
//         {
//             name: "Grip (Medium)",
//             sizes: ["4mm", "5mm"]
//         },
//         {
//             name: "Grip-2 (Souple)",
//             sizes: ["4mm"]
//         },
//     ]
// };



export default function ProductForm({ onSubmit, editable = true }) {
    const { shopId } = useParams();
    const { fetchData } = useAuth();

    const referenceInput = useRef();
    // const commentInput = useRef();

    const [comment, setComment] = useState("");
    const [productType, setProductType] = useState();
    const [works, setWorks] = useState(null);

    const [shop, setShop] = useState();

    const clearForm = () => {
        referenceInput.current.value = "";
        setComment("");
        setProductType("");
        setWorks(null);
    }

    useEffect(() => {
        const loadShop = async () => {
            if (!shopId) return;
            const s = await fetchData({ endpoint: `shops/${shopId}` });
            setShop(s);
        };

        loadShop();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shopId])

    useEffect(() => {
        setWorks(WORKS[productType]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productType]);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const data = {
            reference: referenceInput.current?.value,
            type: productType,
            works: works.filter(w => w.checked).map(w => w.name),
            comments: comment
        };

        clearForm();
        onSubmit(data);
    }

    const handleWorksCheckStatus = (index) => {

        setWorks((currentWork) => currentWork.map((currentWork, currentWorksIndex) =>
            currentWorksIndex === index
                ? { ...currentWork, checked: !currentWork.checked }
                : currentWork
        ));
    }

    const validateForm = () => {
        return (
            ((works && works.filter(w => w.checked).length > 0) || (comment && comment.length > 0)) &&
            (referenceInput.current && referenceInput.current.value.length > 1)
        )
    }

    return (
        <div>
            <h5 className="bold mb-1">
                Prestations
            </h5>
            <div className="row mb-1">
                <div className="col-3">
                    <label id="product-new-reference-label" htmlFor="product-new-reference-input">Numéro ticket</label>
                    <input disabled={!editable} type="text" id="product-new-reference-input" ref={referenceInput} />
                </div>
                <div className="col-3">
                    <label id={`product-new-type-select-label`} htmlFor={`product-new-type-select`}>Type d'article</label>
                    <select id={`product-new-type-select`} name="type" value={productType} onChange={({ target }) => setProductType(target.value)}>
                        <option value="">Sélectionnez un type</option>
                        {
                            shop && shop.products && shop.products.map(p => (
                                <option key={p} value={p}>{translate(p)}</option>
                            ))
                        }
                    </select>
                </div>

                <div className="col-3">
                    <label id="product-comment-reference-label" htmlFor="product-comment-reference-input">Commentaires</label>
                    <input type="text" id="product-comment-reference-input" value={comment} onChange={({ target }) => setComment(target.value)} />
                </div>

                <div className="col-3">
                    <label>Prestations</label>
                    {
                        shop && works &&
                        works.map((work, workIndex) => (
                            <Checkbox
                                index={workIndex}
                                label={work.name}
                                isChecked={work.checked}
                                onCheck={() => handleWorksCheckStatus(workIndex)}
                            />

                        ))
                    }
                    <button disabled={!validateForm()} className="button-s" type="button" onClick={handleFormSubmit}>Ajouter</button>
                </div>

            </div>
        </div>
    )
}