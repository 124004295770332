import React, { useState } from "react";
import { useAuth } from "../../hooks/useAuth";

export default function ChronopostPage() {
  const { fetchData } = useAuth();

  const [values, setValues] = useState(null);
  const [type, setType] = useState(null);

  const isDisabled = () => {
    return (
      !type ||
      !values ||
      !values.name1 ||
      !values.address1 ||
      !values.zipCode ||
      !values.city ||
      !values.phone ||
      !values.email
    );
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setValues({ ...values, [name]: value });
  };

  const handleTypeChange = ({ target }) => {
    const { value } = target;
    setType(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let chrono = await fetchData({
      endpoint: `chronopost/create-custom/${type}`,
      method: "POST",
      data: values,
      isBlob: true,
    });
    let blobUrl = window.URL.createObjectURL(chrono);
    let a = document.createElement("a");

    a.href = blobUrl;
    a.target = "_blank";
    a.download = `custom-chronopost.txt`;
    document.body.appendChild(a);
    a.click();
    a.remove();

    let iframe = document.createElement("iframe"); //load content in an iframe to print later
    document.body.appendChild(iframe);

    iframe.style.display = "none";
    iframe.src = blobUrl;
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  };

  return (
    <>
      <div className="col-12 padding-2 align-center h-100">
        <div className="col-8 mt-2">
          <h4 className="bold">Nouveau Chronopost</h4>
          <div className="bg-white br-4 padding-1">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-6">
                  <div className="col-12 mb-1">
                    <label id="name1-label" htmlFor="name1-input">
                      Nom 1
                    </label>
                    <input
                      type="text"
                      id="name1-input"
                      name="name1"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-12 mb-1">
                    <label id="name2-label" htmlFor="name2-input">
                      Nom 2
                    </label>
                    <input
                      type="text"
                      id="name2-input"
                      name="name2"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-12 mb-1">
                    <label id="contact-label" htmlFor="contact-input">
                      Nom du contact
                    </label>
                    <input
                      type="text"
                      id="contact-input"
                      name="contact"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-12 mb-1">
                    <label id="email-label" htmlFor="email-input">
                      Email
                    </label>
                    <input
                      type="text"
                      id="email-input"
                      name="email"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-12 mb-1">
                    <label id="phone-label" htmlFor="phone-input">
                      Téléphone
                    </label>
                    <input
                      type="text"
                      id="phone-input"
                      name="phone"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="col-12 mb-1">
                    <label id={``} htmlFor={``}>
                      Type
                    </label>
                    <select
                      id={``}
                      value={type}
                      name={`type`}
                      onChange={handleTypeChange}
                    >
                      <option value="">Sélectionnez un type</option>
                      <option value="collection">
                        {"Collecte (client > GP)"}
                      </option>
                      <option value="delivery">
                        {"Livraison (GP > client)"}
                      </option>
                    </select>
                  </div>

                  <div className="col-12 mb-1">
                    <label id="address1-label" htmlFor="address1-input">
                      Adresse 1
                    </label>
                    <input
                      type="text"
                      id="address1-input"
                      name="address1"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-12 mb-1">
                    <label id="address2-label" htmlFor="username-input">
                      Adresse 2
                    </label>
                    <input
                      type="text"
                      id="address2-input"
                      name="address2"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-12 mb-1">
                    <label id="zipCode-label" htmlFor="zipCode-input">
                      Code postal
                    </label>
                    <input
                      type="text"
                      id="zipCode-input"
                      name="zipCode"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-12 mb-1">
                    <label id="city-label" htmlFor="city-input">
                      Ville
                    </label>
                    <input
                      type="text"
                      id="city-input"
                      name="city"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-end mt-1 mb-1">
                <button
                  className="button-s"
                  type="submit"
                  disabled={isDisabled()}
                >
                  Valider
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
