import React from "react";
import { useState } from "react";

export default function AlertDialog({ buttonText, title, message, onAccept, onCancel, question = false }) {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(true);
    };
    const handleClose = () => {
        setIsOpen(false);
    }

    const handleCancel = (e) => {
        e.preventDefault();
        handleClose();
        onCancel && onCancel();
    }

    const handleAccept = (e) => {
        e.preventDefault()
        handleClose();
        onAccept && onAccept();
    }

    const handleButtonClicked = (e) => {
        e.preventDefault();
        handleOpen();
    }

    return (
        <>
            <div className="dialog padding-1 br-4 border-primary bg-white color-primary" style={{ display: isOpen ? "block" : "none" }}>
                {title && <h4>{title}</h4>}
                {message && <p>{message}</p>}
                <menu className="row justify-between">
                    {onCancel && <button className="button-s outlined" onClick={handleCancel}>{question ? "Non" : "Annuler"}</button>}
                    {onAccept && <button className="button-s" onClick={handleAccept}>{question ? "Oui" : "Confirmer"}</button>}
                </menu>
            </div>
            <button className="button-s" onClick={handleButtonClicked}>{buttonText}</button>
        </>
    )
}