export const authenticate = async (data) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/sessions", {
            method: "POST",
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify(data)
        });
        if (response.status !== 200) {
            throw new Error({ error: { msg: "Authentication failed"}})
        }
        return await response.json();
    } catch (error) {
    console.debug("Authentication error", process.env.REACT_APP_API_URL)
    return null;
    }
}