import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ShopOrders from "../../components/ShopOrders";
import { useAuth } from "../../hooks/useAuth";

export default function ShopOrdersPage() {
    const navigate = useNavigate();
    const { fetchData } = useAuth();
    const { shopId } = useParams();

    const [shop, setShop] = useState(null);

    const loadShop = async () => {
        let shop = await fetchData({ endpoint: `shops/${shopId}` });
        setShop(shop);
    };

    useEffect(() => {
        loadShop();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="col-12 padding-2 justify-center align-center">
            <h3 className="bold color-white">{shop && shop.name}</h3>
            <div className="col-12">
                <ShopOrders shopId={shopId} />
                <div className="reverse-row">
                    <button className="button-s" onClick={() => navigate(`/admin/shop/${shopId}/orders/new`)}>Nouvelle commande</button>
                </div>
            </div>
        </div>
    )
}