export default function Checkbox({ isChecked, label, onCheck, disabled = false, index = 0 }) {
    return (
        <div>
            <input
                disabled={disabled}
                type="checkbox"
                id={`checkbox-${index}`}
                checked={isChecked}
                onChange={onCheck}
            />
            <label style={{ marginLeft: "10px" }} htmlFor={`checkbox-${index}`}>{label}</label>
        </div>
    )
}