import { useNavigate, useParams } from "react-router-dom";
import DirectOrders from "../../components/DirectOrders";

export default function ShopDirectPage() {
    const navigate = useNavigate();
    const { shopId } = useParams();

    return (
        <div className="col-12 justify-center align-center padding-2 w-100">
            {
                <>
                    <div className="row justify-center align-center w-100 bg-primary br-4 padding-1">
                        <div className="col-8 color-white">
                            <div className="row w-100">
                                <div className="row flex-1 justify-end">
                                    <button className="button-s" onClick={() => navigate("new")}>Nouvelle commande</button>
                                </div>
                            </div>
                        </div>
                        <hr className="col-12 bg-white color-white mt-1 mb-1" />
                        <DirectOrders shopId={shopId} />
                    </div>

                </>
            }

        </div>
    )
}