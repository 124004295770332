export const formatComments = (comments, work) => {
    if (!comments) return <></>
    return (
        comments.split('\n').map(c => (
            c &&
            <li>{c}</li>
        ))
    )
    // return (<ul>
    //     {
    //         work &&
    //         <li className="bold">{work}</li>
    //     }
    //     {
    //         comments.split('\n').map(c => (
    //             c &&
    //             <li className="bold">{c}</li>
    //         ))}
    // </ul>)
}

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('-');
}

export const formatRawScheduleToStringArray = (rawSchedule) => {
    if (!rawSchedule) return [""];
    let days = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"];
    let splitRaw = rawSchedule.split('');
    let result = [];
    for (let i = 0; i < splitRaw.length; i++) {
        const element = Number(splitRaw[i]);
        if (element)
            result.push(days[i]);
    }
    return result;
}

export const isDayOnSchedule = (day, rawSchedule) => {
    if (!rawSchedule) return false;
    if (day < 1 || day > 5) return false;
    return Number(rawSchedule[day - 1]) > 0;
}

export const printState = (state) => {
    switch (state) {
        case "PENDING":
            return "En attente";
        case "COLLECTION_PENDING":
            return "En attente d'enlèvement";
        case "COLLECTION_IN_PROGRESS":
            return "Collecte en cours";
        case "COLLECTED":
            return "En cours de traitement";
        case "DELIVERY_IN_PROGRESS":
            return "Livraison en cours";
        case "DELIVERED":
            return "Terminé";
        default:
            return "";
    }
}