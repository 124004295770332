import { useEffect, useState } from "react";
import { translate } from "../translation";
import style from "./WorksSelector.module.scss";
import { useAuth } from "../hooks/useAuth";

const WORKS = {
  man_shoes: [
    "Patins H",
    "Talons H",
    "Ressemelage cuir",
    "Fers encastrés",
    "Soin / Cirage",
  ],
  woman_shoes: ["Patins F", "Talons F", "Enveloppes", "Soin / Cirage"],
  kid_shoes: ["Ressemelage", "Soin / Cirage"],
  climbing_shoes: ["Ressemelage", "Enrobage"],
  sneakers: ["Ressemelage", "Soin / Cirage"],
  leather_good: ["Couture FAG", "Soin / Nettoyage", "Teinture"],
};

export default function WorksSelector({ type, onSelected, values }) {
  const [selected, setSelected] = useState(values);
  const [works, setWorks] = useState(WORKS[type]);
  const { fetchData, user } = useAuth();

  async function loadWorktypes() {
    const data = await fetchData({
      endpoint: `worktypes/${type}`,
      method: "GET",
    });

    setWorks(
      data.sort((a, b) => a.name.localeCompare(b.name)).map((d) => d.name)
    );
  }

  useEffect(() => {
    // if (user && user.role === "admin") {
    loadWorktypes();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, type]);

  useEffect(() => {
    setSelected(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  function handleClick(work) {
    let index = selected.findIndex((s) => s === work);
    let newSelection = [...selected];
    if (index < 0) {
      newSelection = [...selected, work];
    } else {
      newSelection.splice(index, 1);
    }

    setSelected(newSelection);
    onSelected && onSelected(newSelection);
  }

  function handleChange({ target }) {
    const { value } = target;

    let selection = [...selected];
    let selectedCommentIndex = -1;
    selection.forEach((sel, selectedIndex) => {
      if (works.some((w) => w === sel) === false) {
        selectedCommentIndex = selectedIndex;
      }
    });

    let tmp = value;
    if (works.some((w) => w === tmp)) {
      tmp = " " + tmp;
    }
    if (selectedCommentIndex > -1) {
      selection[selectedCommentIndex] = tmp;
    } else {
      selection.push(tmp);
    }

    setSelected(selection);
    onSelected && onSelected(selection);
  }

  function getComment() {
    let comment = "";
    values.forEach((val) => {
      if (works.some((w) => w === val) === false) {
        comment = val;
      }
    });
    return comment;
  }
  return (
    <div className={style["root"]}>
      <h3>Interventions*</h3>
      <div className={style["container"]}>
        {type &&
          works.map((t) => (
            <button
              key={t}
              className={`${style["selectable"]} ${
                selected.some((s) => s === t) ? style["selected"] : ""
              }`}
              onClick={() => handleClick(t)}
            >
              {translate(t)}
            </button>
          ))}
        <textarea
          placeholder="Autre(s) intervention(s)"
          name="comments"
          value={getComment()}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}
